import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledTooltip,
} from "reactstrap"
import moment from "moment"
import { getEvents, getEventsLocations } from "./actions"
import { updateEventStatus, deleteEvent } from "./api"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import EventFilters from "./EventsFilter"
import { store as socketStore } from "components/Common/Socket/store"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import Input from "components/Common/Input"

const filterTabs = [
  { label: "Future", value: "future" },
  { label: "Current", value: "current" },
  { label: "Past", value: "past" },
  { label: "All / New", value: "" },
]

const saveState = (event, type) => {
  const colors = {
    grey: new Date() <= new Date(event?.phases?.playing?.startDate),
    red: (event?.[type])?.filter((score) => score === 'none')?.length === event?.[type]?.length,
    orange: !!(event?.[type])?.filter((score) => score === 'partial')?.length ||
      ((event?.[type])?.filter((score) => score === 'none')?.length && (event?.[type])?.filter((score) => score === 'complete')?.length),
    greenf: !!(event?.[type])?.filter((score) => score === 'partialWithoutFinal')?.length,
    green: (event?.[type])?.filter((score) => score === 'complete')?.length === event?.[type]?.length,
  };

  const tooltipText = {
    pointsSaved: {
      grey: 'Not Started Yet',
      red: 'Points Not Saved',
      orange: 'Points Partially Saved',
      greenf: 'Points Saved without Finals',
      green: 'All Points Saved',
    },
    scoresCompletion: {
      grey: 'Not Started Yet',
      red: 'No Scores',
      orange: 'Scores Incomplete',
      greenf: 'Scores Complete without Finals',
      green: 'All Scores Complete',
    },
  };

  return {
    text: (!colors.grey && !colors.red && !colors.orange) && colors.greenf ? 'F' : null,
    exists: !((event?.['scoresCompletion'])?.filter((score) => score === null)?.length === event?.['scoresCompletion']?.length && (event?.['pointsSaved'])?.filter((score) => score === null)?.length === event?.['pointsSaved']?.length),
    color: colors.grey ? 'grey' : colors.red ? 'red' : colors.orange ? 'orange' : !!(colors.green || colors.greenf) ? 'green' : 'grey',
    tooltip: colors.grey ? tooltipText[type]?.grey : colors.red ? tooltipText[type]?.red : colors.orange ? tooltipText[type]?.orange : colors.greenf ? tooltipText[type]?.greenf : colors.green ? tooltipText[type]?.green : tooltipText[type]?.grey,
  };
};

const EventsManagement = ({
  events,
  data,
  fetchEvents,
  fetchEventsLocations,
  eventsLocations,
  userDetails: { role },
}) => {
  const [modal, setModal] = useState(false)
  const [modalEventId, setModalEventId] = useState("")

  const [filters, setFilters] = useState({})
  const [eventTimeFilter, setEventTimeFilter] = useState("current")

  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedEventStatus, setSelectedEventStatus] = useState("")

  const toggle = () => setModal(!modal)

  const initialOptions = {
    page: 1,
    limit: 15,
  }

  const [options, setOptions] = useState(initialOptions)

  const pageOptions = {
    page: events.page,
    sizePerPage: events.limit,
    totalSize: events.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  useEffect(() => {
    fetchEvents(filters, options, eventTimeFilter)
    fetchEventsLocations(filters, eventTimeFilter)
  }, [fetchEvents, filters, options, eventTimeFilter, fetchEventsLocations])

  const handleChangeWithDebounce = _.debounce(async e => {
    setFilters({
      search: e,
    })
    setEventTimeFilter("")
  }, 700)

  const listColumns = () => [
    {
      dataField: "id",
      text: "Id",
      formatter: (x, y) => (
        <Link className="text-dark" to={`/events/${y._id}/details`}>
          <Badge color="primary">{y.eventId}</Badge>
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (x, y) => (
        <Link className="text-dark" to={`/events/${y._id}/details`}>
          {y.name}
        </Link>
      ),
    },
    {
      dataField: "sportType",
      text: "Sport Type",
      formatter: (x, y) => (
        <p className="text-capitalize">
          {y.sportType}
        </p>
      ),
    },
    {
      dataField: "location",
      text: "City",
      formatter: (x, y) => <>{y?.club?.location?.city || "-"} </>,
    },
    {
      dataField: "start Date - End Date",
      text: "Start date - End date",
      formatter: (cellContent, date) => (
        <div className="d-flex flex-column">
          <div>{moment(date.startDate).format("MMMM Do YYYY, h:mm a")}</div>
          <div>{moment(date.endDate).format("MMMM Do YYYY, h:mm a")}</div>
        </div>
      ),
    },
    {
      dataField: "category",
      text: <p className="mb-0 text-center">Category</p>,
      formatter: (cell, y) => (
        <div key={y?._id}>
          <p className="m-0 text-center">{y?.category}</p>
          <div className="d-flex">
            {y?.competitions?.map(competition => (
              <Link
                key={competition?._id}
                to={`/events/${y?._id}/competitions/${competition?._id}/entries`}
              >
                <Badge
                  color={competition?.gameType[0] === "d" ? "primary" : "info"}
                  className="ml-1 mr-1"
                >
                  <p className="m-0">
                    {competition.level + competition.gameType[0].toUpperCase()}
                  </p>
                </Badge>
              </Link>
            ))}
          </div>
        </div>
      ),
    },
    {
      dataField: "playMode",
      text: "Play Mode",
    },
    {
      dataField: "scoresPoints",
      text: <p className="mb-0 text-center">Scores / Points</p>,
      formatter: (cellContent, event) => (
        <div className="d-flex justify-content-center">
          {!!saveState(event)?.exists ? (
            <>
              <div className={`score-status ${saveState(event, 'scoresCompletion')?.color}`} id={`scores-event-${event?.eventId}`}>{saveState(event, 'scoresCompletion')?.text}</div>
              <UncontrolledTooltip placement="top" target={`scores-event-${event?.eventId}`}>
                {saveState(event, 'scoresCompletion')?.tooltip}
              </UncontrolledTooltip>
              <div className={`score-status ${saveState(event, 'pointsSaved')?.color}`} id={`points-event-${event?.eventId}`}>{saveState(event, 'pointsSaved')?.text}</div>
              <UncontrolledTooltip placement="top" target={`points-event-${event?.eventId}`}>
                {saveState(event, 'pointsSaved')?.tooltip}
              </UncontrolledTooltip>
            </>
          ) : 'N/A'}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (x, y) => {
        const checkMaintenance = () => {
          if (y?.competitions?.length > 0) {
            for (var i = 0; i < y?.competitions?.length; i++) {
              const competition = y?.competitions[i]
              if (competition?.status !== "MAINTENANCE") {
                return false
              }
            }
            return true
          }
        }
        return (
          <>
            {checkMaintenance()
              ? "Maintenance"
              : y.status === "DISABLED"
              ? "Inactive"
              : "Active"}
          </>
        )
      },
    },
    {
      dataField: "totalEntries",
      text: (
        <div className="d-flex justify-content-center">
          <p className="mb-0">Total Entries</p>
        </div>
      ),
      formatter: (x, y) => {
        const thisCompetition = y?.competitions?.reduce(
          (acc, currentCompetition) => {
            acc.mlTotal = (acc.mlTotal || 0) + currentCompetition?.mainList
            acc.wlTotal = (acc.wlTotal || 0) + currentCompetition?.waitingList
            acc.mlNumberTotal =
              (acc.mlNumberTotal || 0) + currentCompetition?.mlMaxNumber
            return acc
          },
          {}
        )

        return (
          <div className="d-flex flex-column">
            <Badge color="primary" className="mb-1">
              {" "}
              {thisCompetition?.mlTotal == undefined ? (
                "0/0"
              ) : (
                <p className="mb-0">
                  {thisCompetition?.mlTotal +
                    "/" +
                    thisCompetition?.mlNumberTotal +
                    " ML"}
                </p>
              )}
            </Badge>
            <Badge color="warning">
              {" "}
              {thisCompetition?.wlTotal == undefined ? (
                "0/0"
              ) : (
                <p className="mb-0">{thisCompetition?.wlTotal + "/" + " WL"}</p>
              )}
            </Badge>
          </div>
        )
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (x, y) => (
        <>
          {role === "superAdmin" && (
            <a
              href="javascript:;"
              onClick={() => {
                setDeleteModal(true)
                setModalEventId(y._id)
                toggle()
              }}
              className="button--disable"
            >
              <i className="bx bx-trash-alt"></i>
            </a>
          )}{" "}
          <a
            href="javascript:;"
            onClick={async () => {
              setDeleteModal(false)
              setModalEventId(y._id)
              setSelectedEventStatus(y.status)
              toggle()
              await fetchEvents(filters, options)
            }}
            className={
              y.status === "EVENT_DISABLED"
                ? "button--enable ml-2 mr-2"
                : "button--disable ml-2 mr-2"
            }
          >
            <i className="bx bx-show-alt"></i>
          </a>{" "}
          <Link to={`/events/${y._id}/details`}>
            <i className="bx bx-edit-alt"></i>
          </Link>
        </>
      ),
    },
  ]

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      case "search":
        if (data.searchText !== filters.name) {
          setFilters({
            ...filters,
            search: data.searchText,
          })
        }
        break
      default:
        break
    }
  }

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "DISABLED") {
      return "disabledRow"
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb>
          <BreadcrumbItem active>Events</BreadcrumbItem>
        </Breadcrumb>
        <Nav className="mb-0 d-flex justify-content-between" pills>
          <div className="d-flex ">
            {filterTabs?.map(tab => {
              return (
                <div key={tab?.label}>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setEventTimeFilter(tab?.value)
                      }}
                      className={eventTimeFilter === tab?.value && "active"}
                    >
                      {tab?.label}
                    </NavLink>
                  </NavItem>
                </div>
              )
            })}
          </div>
        </Nav>
        <Card>
          <CardBody>
            <div className="events">
              <Row>
                <Col lg="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={events.results || []}
                        columns={listColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <div className="filter-area">
                              <Row className="mb-3 d-flex justify-content-between">
                                <Col sm="8">
                                  <div className="search-box mr-2 mb-2">
                                    <div className="position-relative">
                                      <i className="bx bx-search-alt search-icon" />
                                      <Input
                                        onChange={e =>
                                          handleChangeWithDebounce(
                                            e?.target?.value
                                          )
                                        }
                                        placeholder="Search by name"
                                      />
                                    </div>
                                  </div>
                                </Col>

                                {/* Create Event Button */}
                                <Col>
                                  <div className="text-sm-right">
                                    <Link
                                      to="/events"
                                      className="btn-rounded waves-effect waves-light mb-2  btn btn-success"
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      Create Event
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <EventFilters
                                eventsLocations={eventsLocations}
                                {...{ filters, setFilters }}
                              />
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    rowClasses={rowClasses}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            {!events && <p>There are no events yet</p>}

                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {deleteModal
            ? "Remove event confirmation"
            : `${
                selectedEventStatus === "DISABLED"
                  ? "ENABLE EVENT"
                  : "DISABLE EVENT"
              }`}
        </ModalHeader>
        <ModalBody>
          {deleteModal ? (
            "All information entered will be lost if you delete the event. Are you sure?"
          ) : selectedEventStatus === "DISABLED" ? (
            <p className="mb-0">
              Are you sure you want to{" "}
              <span className="font-weight-bold">ENABLE</span> this event?
              (ACTIVE events are visible on the Website)
            </p>
          ) : (
            <p className="mb-0">
              Are you sure you want to{" "}
              <span className="font-weight-bold">DISABLE</span> this event?
              (INACTIVE events are not visible on the Website)
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={
              deleteModal
                ? async () => {
                    await deleteEvent(modalEventId)
                    await fetchEvents(filters, options)
                    toggle()
                  }
                : async () => {
                    console.log("req here")
                    await updateEventStatus(
                      modalEventId,
                      selectedEventStatus === "DISABLED"
                        ? "accepted"
                        : "disabled",
                      socketClientId
                    )
                    await fetchEvents(filters, options)
                    toggle()
                  }
            }
            color="primary"
          >
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            no
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStatetoProps = ({
  events: { events = [], eventsLocations = [] } = {},
  session: { userDetails } = {},
}) => ({
  events,
  eventsLocations,
  userDetails,
})

export default connect(mapStatetoProps, {
  fetchEvents: (filters, options, eventTimeFilter) =>
    getEvents(filters, options, eventTimeFilter),
  fetchEventsLocations: (filters, time) => getEventsLocations(filters, time),
})(EventsManagement)
