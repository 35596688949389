import React, { useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import {Alert, Container, Spinner} from "reactstrap"
import toastr from "toastr"

import { store as socketStore } from "../../../components/Common/Socket/store"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getClubInfo, getClubOwner, unsetClubOwner } from "./actions"
import { updateClub } from "./api"
import FormData from "./FormData"
import moment from "moment"

const EditClub = props => {
  const {
    clubInfo,
    getClubInfo,
    clubOwner,
    getClubOwner,
    unsetClubOwner,
    match: { params },
    history,
    session,
  } = props

  const { settings } = session

  const [isLoading, setIsLoading] = useState(false)
  const globalState = useContext(socketStore)
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message)
        setIsLoading(false)
        return
      }

      toastr.success(message)
      history.goBack()
    }

    if (socket) {
      socket.removeAllListeners("clubs-response")
      socket.on("clubs-response", listenForResponse)

      return () => {
        socket.removeAllListeners("clubs-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  const [selectedAmenities, setSelectedAmenities] = useState([])
  const [selectedCourtTypes, setSelectedCourtTypes] = useState([]);

  //values for days
  const [openHours, setOpenHours] = useState({})
  const [exclusiveOffer, setExclusiveOffer] = useState(clubInfo?.exclusiveOffer);

  const handleSubmit = async (event, values) => {
    setIsLoading(true)
    // set location
    values.location = values.location.value
    // set organizer location
    if (!isEmpty(values.organizerInfo?.location)) {
      values.organizerInfo.location = values.organizerInfo.location.value
    }

    const response = await updateClub(params.id, {
      socketClientId,
      amenities: selectedAmenities,
      openHours,
      ...values,
      exclusiveOffer: {
        ...exclusiveOffer,
        weekday: {
          ...exclusiveOffer?.weekday?.pricePerHour && {
            ...values.exclusiveOffer?.weekday,
            ...exclusiveOffer.weekday,
            ...exclusiveOffer.weekday.startHour && {
              startHour: moment(exclusiveOffer.weekday.startHour, "HH:mm").format("HH:mm"),
            },
            ...exclusiveOffer.weekday.endHour && {
              endHour: moment(exclusiveOffer.weekday.endHour, "HH:mm").format("HH:mm"),
            }
          },
        },
        weekend: {
          ...exclusiveOffer?.weekend?.pricePerHour && {
            ...values.exclusiveOffer?.weekend,
            ...exclusiveOffer?.weekend,
            ...exclusiveOffer?.weekend.startHour && {
              startHour: moment(exclusiveOffer.weekend.startHour, "HH:mm").format("HH:mm"),
            },
            ...exclusiveOffer.weekend.endHour && {
              endHour: moment(exclusiveOffer.weekend.endHour, "HH:mm").format("HH:mm"),
            },
          }
        },
      },
    })
    if (!response.success) {
      toastr.error(response.message || "Club could not be created.")
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setOpenHours({
      Mon: {
        ...(clubInfo?.openHours?.Mon?.startDate && {
          startDate: clubInfo?.openHours?.Mon?.startDate
            ? clubInfo?.openHours?.Mon?.startDate
            : null,
        }),
        ...(clubInfo?.openHours?.Mon?.endDate && {
          endDate: clubInfo?.openHours?.Mon?.endDate
            ? clubInfo?.openHours?.Mon?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Mon?.enabled && {
          enabled: clubInfo?.openHours?.Mon?.enabled
            ? clubInfo?.openHours?.Mon?.enabled
            : false,
        }),
      },
      Tue: {
        ...(clubInfo?.openHours?.Tue?.startDate && {
          startDate: clubInfo?.openHours?.Tue?.startDate
            ? clubInfo?.openHours?.Tue?.startDate
            : null,
        }),

        ...(clubInfo?.openHours?.Tue?.endDate && {
          endDate: clubInfo?.openHours?.Tue?.endDate
            ? clubInfo?.openHours?.Tue?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Tue?.enabled && {
          enabled: clubInfo?.openHours?.Tue?.enabled
            ? clubInfo?.openHours?.Tue?.enabled
            : false,
        }),
      },

      Wed: {
        ...(clubInfo?.openHours?.Wed?.startDate && {
          startDate: clubInfo?.openHours?.Wed?.startDate
            ? clubInfo?.openHours?.Wed?.startDate
            : null,
        }),
        ...(clubInfo?.openHours?.Wed?.endDate && {
          endDate: clubInfo?.openHours?.Wed?.endDate
            ? clubInfo?.openHours?.Wed?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Wed?.enabled && {
          enabled: clubInfo?.openHours?.Wed?.enabled
            ? clubInfo?.openHours?.Wed?.enabled
            : false,
        }),
      },
      Thu: {
        ...(clubInfo?.openHours?.Thu?.startDate && {
          startDate: clubInfo?.openHours?.Thu?.startDate
            ? clubInfo?.openHours?.Thu?.startDate
            : null,
        }),
        ...(clubInfo?.openHours?.Thu?.endDate && {
          endDate: clubInfo?.openHours?.Thu?.endDate
            ? clubInfo?.openHours?.Thu?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Thu?.enabled && {
          enabled: clubInfo?.openHours?.Thu?.enabled
            ? clubInfo?.openHours?.Thu?.enabled
            : false,
        }),
      },
      Fri: {
        ...(clubInfo?.openHours?.Fri?.startDate && {
          startDate: clubInfo?.openHours?.Fri?.startDate
            ? clubInfo?.openHours?.Fri?.startDate
            : null,
        }),
        ...(clubInfo?.openHours?.Fri?.endDate && {
          endDate: clubInfo?.openHours?.Fri?.endDate
            ? clubInfo?.openHours?.Fri?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Fri?.enabled && {
          enabled: clubInfo?.openHours?.Fri?.enabled
            ? clubInfo?.openHours?.Fri?.enabled
            : false,
        }),
      },

      Sat: {
        ...(clubInfo?.openHours?.Sat?.startDate && {
          startDate: clubInfo?.openHours?.Sat?.startDate
            ? clubInfo?.openHours?.Sat?.startDate
            : null,
        }),
        ...(clubInfo?.openHours?.Sat?.endDate && {
          endDate: clubInfo?.openHours?.Sat?.endDate
            ? clubInfo?.openHours?.Sat?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Sat?.enabled && {
          enabled: clubInfo?.openHours?.Sat?.enabled
            ? clubInfo?.openHours?.Sat?.enabled
            : false,
        }),
      },
      Sun: {
        ...(clubInfo?.openHours?.Sun?.startDate && {
          startDate: clubInfo?.openHours?.Sun?.startDate
            ? clubInfo?.openHours?.Sun?.startDate
            : null,
        }),
        ...(clubInfo?.openHours?.Sun?.endDate && {
          endDate: clubInfo?.openHours?.Sun?.endDate
            ? clubInfo?.openHours?.Sun?.endDate
            : null,
        }),
        ...(clubInfo?.openHours?.Sun?.enabled && {
          enabled: clubInfo?.openHours?.Sun?.enabled
            ? clubInfo?.openHours?.Sun?.enabled
            : false,
        }),
      },
    });
    if (clubInfo?.amenities) {
      setSelectedAmenities(clubInfo?.amenities);
    }
    if (clubInfo?.exclusiveOffer) {
      setExclusiveOffer(clubInfo?.exclusiveOffer);
    }
  }, [clubInfo])

  useEffect(() => {
    getClubInfo(params.id)
  }, [getClubInfo, params.id])

  useEffect(() => {
    if (!isEmpty(clubInfo)) {
      if (clubInfo.ownerId) {
        getClubOwner(clubInfo.ownerId)
      } else {
        unsetClubOwner()
      }
    }
  }, [getClubOwner, clubInfo, unsetClubOwner])

  return (
    <div className="page-content">
      <Container fluid={true}>
        { clubInfo.id && !clubInfo.ownerId && (
          <Alert color="danger">
            This club doesn't have an owner account.
          </Alert>
        )}
        <Breadcrumbs
          path="/accounts/clubs"
          title="User Accounts"
          breadcrumbItem="Club Info"
          displayTotal={`#${clubInfo?.clubId}`}
        />
        {!clubInfo && params.id ? (
          <div className="text-center">
            <Spinner className="mt-5 mb-5" color="primary" />
          </div>
        ) : (
          <FormData
            openHours={openHours}
            setOpenHours={setOpenHours}
            selectedAmenities={selectedAmenities}
            setSelectedAmenities={setSelectedAmenities}
            handleSubmit={handleSubmit}
            clubInfo={clubInfo}
            clubOwner={clubOwner}
            history={history}
            loading={isLoading}
            eligibleCountries={settings?.eligibleCountries}
            defaultBookingSettings={settings?.bookingSettings}
            eventCategories={settings?.categories}
            exclusiveOffer={exclusiveOffer}
            setExclusiveOffer={setExclusiveOffer}
            activeSports={settings?.activeSports}
            courtTypes={settings?.courtTypes}
          />
        )}
      </Container>
    </div>
  )
}

EditClub.propTypes = {
  clubInfo: PropTypes.object,
  getClubInfo: PropTypes.func,
  clubOwner: PropTypes.object,
  getClubOwner: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ clubs, session }) => ({
  loading: clubs.loading,
  clubInfo: clubs.clubInfo,
  clubOwner: clubs.clubOwner,
  session: session,
})

const mapDispatchToProps = dispatch => ({
  getClubInfo: id => dispatch(getClubInfo(id)),
  getClubOwner: id => dispatch(getClubOwner(id)),
  unsetClubOwner: () => dispatch(unsetClubOwner()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditClub))
