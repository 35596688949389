import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import toastr from "toastr"
import SweetAlert from "react-bootstrap-sweetalert"
import { capitalize, isEmpty } from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Table,
  Alert,
} from "reactstrap"
import moment from "moment"

import defaultProfilePicture from "../../../../assets/images/users/default-profile-picture.png"
import partnerClubSvg from "../../../../assets/images/partner_club.svg"

import Breadcrumbs from "components/Common/Breadcrumb"

import { getClubInfo, getClubOwner, getClubOrganizers } from "../actions"
import { deleteClub, updateStatus } from "../api"

import { store as socketStore } from "../../../../components/Common/Socket/store"

const ClubProfile = props => {
  const {
    clubInfo,
    userId,
    impersonateKey,
    getClubInfo,
    clubOwner,
    getClubOwner,
    clubOrganizers,
    getClubOrganizers,
    loading,
    match: { params },
    settings,
  } = props

  const [blockAlert, setBlockAlert] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [activateAlert, setActivateAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState()
  const [alertDescription, setAlertDescription] = useState()
  const [hideOwnerIdAlert, setHideOwnerIdAlert] = useState(false)

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId, socket } = {} } = {} } =
    globalState

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        switch (message) {
          case "ACCOUNT_ALREADY_EXIST":
            toastr.error(
              "Couldn't create club account. Update club owner email and try again."
            )
            break
          default:
            toastr.error(message)
        }
      } else {
        setHideOwnerIdAlert(true)
      }
    }

    if (socket) {
      socket.removeAllListeners("accounts-response")
      socket.on("accounts-response", listenForResponse)

      return () => {
        socket.removeAllListeners("accounts-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  useEffect(() => {
    getClubInfo(params.id)
  }, [getClubInfo, params.id])

  useEffect(() => {
    if (!isEmpty(clubInfo) && clubInfo.ownerId) {
      getClubOwner(clubInfo.ownerId)
    }
  }, [getClubOwner, clubInfo])

  useEffect(() => {
    if (!isEmpty(clubInfo) && clubInfo.id) {
      getClubOrganizers(clubInfo.id);
    }
  }, [clubInfo, getClubOrganizers])

  const handleBlock = async () => {
    const result = await updateStatus(params.id, "blocked")

    if (result?.success) {
      setBlockAlert(false)
      setSuccessAlert(true)
      setAlertTitle("Blocked")
      setAlertDescription("Club was successfully blocked.")
      getClubInfo(params.id)
    } else {
      toastr.error(result?.message || "Club could not be blocked.")
    }
  }

  const handleDelete = async () => {
    const result = await deleteClub(params.id)

    if (result?.success) {
      setDeleteAlert(false)
      toastr.success("Club was successfully deleted.")
      props.history.push("/accounts/clubs")
    } else {
      toastr.error(result?.message || "Club could not be deleted.")
    }
  }

  const handleActivate = async () => {
    const result = await updateStatus(params.id, "activated")

    if (result?.success) {
      setActivateAlert(false)
      setSuccessAlert(true)
      setAlertTitle("Activated")
      setAlertDescription("Club was successfully activated.")
      getClubInfo(params.id)
    } else {
      toastr.error(result?.message || "Club could not be activated.")
    }
  }

  const clubTos = clubInfo?.osTos?.acceptedVersion ?? 0;
  const systemTos = settings?.osTosVersion ?? 0;

  let tosAccepted = <span className="text-danger">NO</span>;
  if (clubTos === systemTos) {
    tosAccepted = <span className="text-success">YES ({moment(clubInfo?.osTos?.dateAccepted).format("DD.MM.YYYY")})</span>
  } else if (clubTos > 0 ) {
    tosAccepted = <span className="text-danger">NO (last accepted v{clubTos} on {moment(clubInfo?.osTos?.dateAccepted).format("DD.MM.YYYY")})</span>
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {clubInfo.id && !clubInfo.ownerId && !hideOwnerIdAlert && (
            <Alert color="danger">
              This club doesn't have an owner account.
            </Alert>
          )}
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            path="/accounts/clubs"
            title="Accounts"
            breadcrumbItem="Club Profile"
          />

          {loading ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody className="d-flex justify-content-between align-items-center" style={{minHeight: '72px', padding: '5px 20px'}}>
                    <h4 className="mb-0">
                      #{clubInfo.clubId} - {clubInfo.clubName}
                      {clubInfo.partnerClub && <img
                        src={partnerClubSvg}
                        alt="Sportya Partner Club"
                        className="ml-4"
                      />}
                    </h4>
                    <div>
                      {clubOwner && clubInfo?.status !== "blocked" && (
                        <Button
                          onClick={async () => {
                            try {
                              const req = new XMLHttpRequest()
                              req.onreadystatechange = function () {
                                if (
                                    this.readyState == 4 &&
                                    this.status == 200
                                  ) {
                                     window.open(process.env.REACT_APP_OS_URL)
                                  }
                                 }
                              req.open(
                                "POST",
                                `${process.env.REACT_APP_OS_URL}/api/auth`,
                                true
                              )
                              req.withCredentials = true;
                              req.setRequestHeader(
                                "Content-type",
                                "application/x-www-form-urlencoded"
                              )
                              req.send(
                                `hash=${impersonateKey}&userId=${userId}&email=${clubInfo?.ownerInfo?.email}`
                              )
                            } catch {}
                          }}
                        >
                          Impersonate
                        </Button>
                      )}

                      {clubInfo.status !== "blocked" && (
                        <Button
                          className="btn btn-warning waves-effect waves-light btn-md ml-3"
                          onClick={() => {
                            setBlockAlert(true)
                          }}
                        >
                          Block
                        </Button>
                      )}

                      {clubInfo.status !== "activated" && (
                        <Button
                          className="btn btn-success waves-effect waves-light btn-md ml-3"
                          onClick={() => {
                            setActivateAlert(true)
                          }}
                        >
                          {clubInfo.status === "blocked"
                            ? "Unblock"
                            : "Activate"}
                        </Button>
                      )}

                      {clubInfo.status !== "deleted" && (
                        <Button
                          className="btn btn-danger waves-effect waves-light btn-md ml-3"
                          onClick={() => {
                            setDeleteAlert(true)
                          }}
                        >
                          Delete
                        </Button>
                      )}

                      <Link
                        to={`/accounts/clubs/${clubInfo.id}/edit`}
                        className="btn btn-light waves-effect waves-light btn-md ml-3"
                      >
                        Edit
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3"></div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        {/* <img src={profile1} alt="" className="img-fluid" /> */}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="3">
                        <div className="avatar-lg profile-user-wid mb-4">
                          <img
                            src={`${
                              clubInfo.profilePicture
                                ? `${process.env.REACT_APP_API_URL}/file/${clubInfo.profilePicture}`
                                : defaultProfilePicture
                            }`}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <h5 className="font-size-15">Role</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                club
                              </p>
                            </Col>
                            <Col xs="4">
                              <h5 className="font-size-15">Status</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {clubInfo.status}
                              </p>
                            </Col>
                            <Col xs="4">
                              <h5 className="font-size-15">Booking Enabled</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {clubInfo.bookingSettings?.bookingEnabled ? 'Yes' : 'No'}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <div className="font-size-15 mb-1">
                          <span className="font-weight-bold mb-0 text-truncate">
                            Business name:
                          </span>{" "}
                          {clubInfo.businessName}
                        </div>
                      </Col>
                      <Col sm={4} className="text-right">
                        <div className="mt-0">
                          <a
                            className="btn btn-primary waves-effect waves-light btn-sm"
                            href={`${process.env.REACT_APP_WEB_URL}/clubs/${clubInfo.id}`}
                            target="_blank"
                          >
                            View Club <i className="mdi mdi-arrow-right ms-1" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Club info</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row" style={{width: '480px'}}>Club name: </th>
                            <td>{clubInfo.clubName}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{width: '480px'}}>Sports: </th>
                            <td>{(clubInfo.sports || [])?.map((sport) => capitalize(sport)).join(", ")}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{width: '480px'}}>Location: </th>
                            <td>{clubInfo.location?.name || "n/a"}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{width: '480px'}}>Organizer: </th>
                            <td>{clubOwner?.isOrganizer ? "Yes" : "No"}</td>
                          </tr>
                          <tr>
                            <th scope="row" style={{width: '480px'}}>{`Accepted Terms of Service v${settings?.osTosVersion}: `}</th>
                            <td>{tosAccepted}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Owner info</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name: </th>
                            <td>
                              {clubInfo.ownerInfo?.firstName}{" "}
                              {clubInfo.ownerInfo?.lastName}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Phone: </th>
                            <td>{clubInfo.ownerInfo?.phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email: </th>
                            <td>
                              <a href={`mailto:${clubInfo.ownerInfo?.email}`}>
                                {clubInfo.ownerInfo?.email}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Contact info</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">First name:</th>
                            <td>{clubInfo.contactInfo?.firstName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Last name:</th>
                            <td>{clubInfo.contactInfo?.lastName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email:</th>
                            <td>
                              <a href={`mailto:${clubInfo.contactInfo?.email}`}>
                                {clubInfo.contactInfo?.email}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Phone:</th>
                            <td>{clubInfo.contactInfo?.phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">Website:</th>
                            <td>{clubInfo.contactInfo?.website || "-"}</td>
                          </tr>
                          <tr>
                            <th scope="row">About:</th>
                            <td>{clubInfo.description || "-"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Assigned Organizers</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          {!!clubOrganizers?.results &&
                            <tr>
                              <th>Organizer name</th><th>Location</th>
                            </tr>}
                          {clubOrganizers?.results?.length ?
                            clubOrganizers.results.sort((organizer) => organizer.id === clubInfo.ownerId ? -1 : 1).map((organizer) => (
                              <tr>
                                <td>
                                  <Link to={`/accounts/organizers/${organizer.id}`}>
                                    {organizer.organizerInfo.clientType === 'company' ? organizer.organizerInfo.companyName : `${organizer.organizerInfo.firstName} ${organizer.organizerInfo.lastName}`}{organizer.id === clubInfo.ownerId ? ' (Self)' : ''}
                                  </Link>
                                </td>
                                <td>{organizer.organizerInfo.location.name}</td>
                              </tr>
                            ))
                          :
                          <tr>
                            <th scope="row">No organizers are assigned for this club.</th>
                          </tr>}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Open Hours</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th>Day</th>
                            <th>Start interval</th>
                            <th>End interval</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clubInfo?.openHours
                            ? Object?.keys(clubInfo?.openHours)?.map(
                                item =>
                                  clubInfo?.openHours[item]?.enabled && (
                                    <tr key={item}>
                                      <th className="text-capitalize">
                                        {item}
                                      </th>
                                      <td>
                                        {clubInfo?.openHours[item]?.startDate
                                          ? moment(
                                              clubInfo?.openHours[item]
                                                ?.startDate
                                            ).format("hh:mm")
                                          : "8:00"}
                                      </td>
                                      <td>
                                        {clubInfo?.openHours[item]?.endDate
                                          ? moment(
                                              clubInfo?.openHours[item]?.endDate
                                            ).format("hh:mm")
                                          : "22:00"}{" "}
                                      </td>
                                    </tr>
                                  )
                              )
                            : ""}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Booking Info</CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Booking Access Status:</th>
                                <td align="right" className="text-capitalize">{clubInfo?.bookingAccess}</td>
                              </tr>
                              {['enabled', 'blocked'].includes(clubInfo?.bookingAccess) &&
                              <>
                                <tr>
                                  <th scope="row">OS Booking Enabled:</th>
                                  <td align="right">{clubInfo?.bookingSettings?.bookingEnabled ? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Application fee (%):</th>
                                  <td align="right">{clubInfo?.bookingSettings?.applicationFee}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Book in advance (days):</th>
                                  <td align="right">{clubInfo?.bookingSettings?.bookInAdvance}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Minimum cancellation time (hours):</th>
                                  <td align="right">{clubInfo?.bookingSettings?.minCancellationTime}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Email notifications:</th>
                                  <td align="right">{clubInfo?.bookingSettings?.notificationsEmails}</td>
                                </tr>
                              </>}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Social Media</CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Facebook:</th>
                                <td>
                                  <a
                                    target="_blank"
                                    href={`${clubInfo?.facebookUrl}`}
                                  >
                                    {clubInfo?.facebookUrl || "-"}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Instagram:</th>
                                <td>
                                  <a
                                    target="_blank"
                                    href={`${clubInfo?.instagramUrl}`}
                                  >
                                    {clubInfo?.instagramUrl || "-"}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Twitter:</th>
                                <td>
                                  <a
                                    target="_blank"
                                    href={`${clubInfo?.twitterUrl}`}
                                  >
                                    {clubInfo?.twitterUrl || "-"}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Booking Exclusive Offer</CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                            <tr>
                              <th scope="row">Status</th>
                              <td>{clubInfo?.exclusiveOffer?.status} {new Date(clubInfo?.exclusiveOffer?.endDate) < new Date() ? '(expired)' : ''}</td>
                            </tr>
                            {clubInfo?.exclusiveOffer?.status === 'active' && (<>
                              <tr>
                                <th scope="row">Availability</th>
                                <td>{moment(clubInfo?.exclusiveOffer.startDate).format("YYYY-MM-DD")} - {moment(clubInfo?.exclusiveOffer.endDate).format("YYYY-MM-DD")}</td>
                              </tr>
                              {clubInfo?.exclusiveOffer?.weekday?.pricePerHour && (
                                <tr>
                                  <th scope="row">Weekday</th>
                                  <td>{clubInfo?.exclusiveOffer?.weekday?.pricePerHour} RON, {clubInfo?.exclusiveOffer?.weekday?.startDay}@{clubInfo?.exclusiveOffer?.weekday?.startHour} - {clubInfo?.exclusiveOffer?.weekday?.endDay}@{clubInfo?.exclusiveOffer?.weekday?.endHour}</td>
                                </tr>
                              )}
                              {clubInfo?.exclusiveOffer?.weekend?.pricePerHour && (
                                <tr>
                                  <th scope="row">Weekday</th>
                                  <td>{clubInfo?.exclusiveOffer?.weekend?.pricePerHour} RON, {clubInfo?.exclusiveOffer?.weekend?.startDay}@{clubInfo?.exclusiveOffer?.weekend?.startHour} - {clubInfo?.exclusiveOffer?.weekend?.endDay}@{clubInfo?.exclusiveOffer?.weekend?.endHour}</td>
                                </tr>
                              )}
                            </>)}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Container>

        {successAlert ? (
          <SweetAlert
            success
            title={alertTitle || "Success"}
            onConfirm={() => {
              setSuccessAlert(false)
            }}
          >
            {alertDescription}
          </SweetAlert>
        ) : null}

        {blockAlert ? (
          <SweetAlert
            title="Block club?"
            warning
            showCancel
            confirmButtonText="Yes, block it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleBlock}
            onCancel={() => setBlockAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {deleteAlert ? (
          <SweetAlert
            title="Delete club?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleDelete}
            onCancel={() => setDeleteAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {activateAlert ? (
          <SweetAlert
            title="Activate club?"
            warning
            showCancel
            confirmButtonText="Yes, activate it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleActivate}
            onCancel={() => setActivateAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}

ClubProfile.propTypes = {
  clubInfo: PropTypes.object,
  getClubInfo: PropTypes.func,
  clubOwner: PropTypes.object,
  getClubOwner: PropTypes.func,
  clubOrganizers: PropTypes.object,
  getClubOrganizers: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ clubs, session: { userDetails, settings } = {} }) => ({
  loading: clubs.loading,
  clubInfo: clubs.clubInfo,
  clubOwner: clubs.clubOwner,
  clubOrganizers: clubs.clubOrganizers,
  userId: userDetails.userId,
  impersonateKey: userDetails.impersonateKey,
  settings,
})

const mapDispatchToProps = dispatch => ({
  getClubInfo: id => dispatch(getClubInfo(id)),
  getClubOwner: id => dispatch(getClubOwner(id)),
  getClubOrganizers: id => dispatch(getClubOrganizers(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClubProfile))
