import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { FormGroup, Row, Col, Label } from "reactstrap"
import Input from "components/Common/Input"
import { capitalize } from "lodash"

const penaltiesTypes = [
  { label: "Announced Withdrawal", value: "ANNOUNCED_WITHDRAWAL" },
  { label: "Late Withdrawal", value: "LATE_WITHDRAWAL" },
  { label: "No Show", value: "NO_SHOW" },
  { label: "Administrative Penalty", value: "ADMINISTRATIVE_PENALTY" },
]

const AdvancedFilters = ({
  touched,
  initialValues,
  handleBlur,
  handleChange,
  setFieldValue,
  values,
  settings,
}) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Input
              placeholder="Search by player id, player name, event id, competition id"
              label="Search"
              type="text"
              name="search"
              {...{
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            ></Input>
          </FormGroup>
        </Col>
      </Row>
      <Row className="w-100">
        <Col md={4}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
            <Label>Sport Type</Label>
            <Select
              placeholder="All"
              name="sportType"
              onChange={(e, x) => {
                x?.removedValues?.length
                  ? setFieldValue("sportType", "")
                  : setFieldValue("sportType", e?.value)
              }}
              options={settings?.activeSports?.map(e => ({
                value: e,
                label: capitalize(e),
              }))}
              isClearable={true}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
            <Label>Draw Type</Label>
            <Select
              placeholder="Draw Type"
              name="drawType"
              onChange={(e, x) => {
                x?.removedValues?.length
                  ? setFieldValue("drawType", "")
                  : setFieldValue("drawType", e?.value)
              }}
              options={settings?.gameTypes?.map(e => ({
                value: e?.key,
                label: e?.name,
              }))}
              isClearable={true}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
            <Label>Penalty Type</Label>
            <Select
              placeholder="Penalty Type"
              name="penaltyType"
              onChange={(e, x) =>
                x?.removedValues?.length
                  ? setFieldValue("penaltyType", "")
                  : setFieldValue("penaltyType", e?.value)
              }
              options={penaltiesTypes}
              isClearable={true}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

const mapStatetoProps = ({ session: { settings } = {} }) => ({
  settings,
})

export default connect(mapStatetoProps, {})(AdvancedFilters)
