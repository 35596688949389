import React, { useState, useContext } from "react"

import { Formik, Form } from "formik"
import { useToasts } from "react-toast-notifications"
import * as Yup from "yup"
import { connect } from "react-redux"

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { createEvent } from "pages/EventsManagement/api"
import moment from "moment"
import components from "./components"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { store as socketStore } from "components/Common/Socket/store"
import { Link } from "react-router-dom"
import * as actions from "../../../../../pages/Authentication/actions"
import { isEmpty } from "lodash";

const phaseStatusSchema = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string(),
})

const phaseSchema = Yup.object().shape({
  openingSoon: phaseStatusSchema,
  playing: phaseStatusSchema,
  preRegistrationsOpen: Yup.object(),
  registrationsOpen: phaseStatusSchema,
  sortPending: Yup.object(),
})

const EventInfo = Yup.object().shape({
  coordinator: Yup.object().required("Required"),
  name: Yup.string().required("Required"),
  club: Yup.object().required("Required"),
  organizer: Yup.object().nullable(),
  // "organizer.phone": Yup.string().when(["club", "organizer"], (club, organizer) => {
  //   if (club?.clubName !== null || organizer?.firstName !== "No") {
  //     return Yup.string().required("Required")
  //   }
  // }),
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
  category: Yup.string().required("Required"),
  season: Yup.string().required("Required"),
  playMode: Yup.string().required("Required"),
  singlesMaxNumber: Yup.number().required("Required"),
  doublesMaxNumber: Yup.number().required("Required"),
  courtType: Yup.string().required("Required"),
  phase: phaseSchema,
})

const Details = ({
  getEvents,
  activeEvent,
  id,
  history,
  settings,
  getSession,
  activeEvent: {
    eventId,
    sportType = "tennis",
    coordinator = "",
    name = "",
    sponsor = "",
    club = "",
    organizer = "",
    directorName = "",
    phone = "",
    email = "",
    refereeName = "",
    supervisorName = "",
    playerPackage = {},
    startDate = "",
    endDate = "",
    category = "",
    season = "",
    playMode = "",
    singlesMaxNumber = "",
    doublesMaxNumber = "",
    numberOfCourts = 0,
    courtType = "",
    officialBall = "",
    description = "",
    location = "",
    featured = false,
    image,
    phases = {
      openingSoon: { startDate, endDate },
      preRegistrationsOpen: { startDate, endDate },
      sortPending: { startDate, endDate },
      registrationsOpen: { startDate, endDate },
      drawPending: { startDate, endDate },
      readyToPlay: { startDate, endDate },
      playing: { startDate, endDate },
      resultsPending: { startDate, endDate },
      ended: { startDate, endDate },
    },
  } = {},
}) => {
  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const listenForResponse = async ({
    success,
    message,
    data: { id: newId } = {},
  }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }

    if (!id) {
      history.push(`/events/${newId}/details`)
    }

    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const [modal, setModal] = useState(false)

  // default dates for start and end
  let defaultStartDate = new Date()
  let defaultEndDate = new Date()
  defaultStartDate.setHours("00")
  defaultStartDate.setMinutes("0")
  defaultEndDate.setHours("23")
  defaultEndDate.setMinutes("59")

  const goTo = (link, filename) => {
    let xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = function () {
      let a
      if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
        a = document.createElement("a")
        a.href = window.URL.createObjectURL(xmlHttpRequest.response)
        a.download = filename
        a.style.display = "none"
        document.body.appendChild(a)
        a.click()
      }
    }
    xmlHttpRequest.open("GET", link, true)
    xmlHttpRequest.withCredentials = true
    xmlHttpRequest.responseType = "blob"
    xmlHttpRequest.send()
  }

  const toggle = () => setModal(!modal)
  return (
    <div>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      <Formik
        key={`${name} ${settings?.playerPackages[0]?.ro?.title}`}
        initialValues={{
          sportType,
          coordinator: coordinator,
          name,
          sponsor: sponsor,
          club: club,
          organizer: organizer,
          directorName,
          phone: organizer?.phone,
          email: organizer?.email,
          refereeName,
          supervisorName,
          startDate: !id ? defaultStartDate : startDate,
          endDate: !id ? defaultEndDate : endDate,
          category,
          season,
          playMode: !id ? "weekend" : playMode,
          singlesMaxNumber: singlesMaxNumber ? singlesMaxNumber : 1,
          doublesMaxNumber: doublesMaxNumber ? doublesMaxNumber : 1,
          numberOfCourts,
          courtType,
          officialBall,
          description,
          featured,
          location: location ? location : "",
          phase: phases,
          logo: image?.logo ? image?.logo : "",
          featuredImg: image?.featured ? image?.featured : "",
          cover: image?.cover ? image?.cover : "",
          playerPackageTitle:
            playerPackage?.ro?.title || settings?.playerPackages[0]?.ro?.title,
          playerPackageSummary:
            playerPackage?.ro?.summary ||
            settings?.playerPackages[0]?.ro?.summary,
          playerPackageDescription:
            playerPackage?.ro?.description ||
            settings?.playerPackages[0]?.ro?.description,
          tShirt: activeEvent?.playerPackage?.tShirt || false,
          playerPackageTitleEn:
            playerPackage?.en?.title || settings?.playerPackages[0]?.en?.title,
          playerPackageSummaryEn:
            playerPackage?.en?.summary ||
            settings?.playerPackages[0]?.en?.summary,
          playerPackageDescriptionEn:
            playerPackage?.en?.description ||
            settings?.playerPackages[0]?.en?.description,
        }}
        validationSchema={EventInfo}
        onSubmit={async ({
          club,
          sponsor,
          organizer,
          sportType,
          coordinator,
          playerPackage,
          category,
          logo,
          cover,
          featuredImg,
          phase: { preRegistrationsOpen, sortPending, ...phase },
          location,
          playerPackageTitle,
          playerPackageSummary,
          playerPackageDescription,
          playerPackageTitleEn,
          playerPackageSummaryEn,
          playerPackageDescriptionEn,
          tShirt,
          email,
          phone,
          ...values
        }) => {
          let phases = ["gold", "platinum", "champions"].includes(category)
            ? { preRegistrationsOpen, sortPending, ...phase }
            : phase
          phases = id ? phases : {}

          try {
            await getSession()

            if (!id && !isEmpty(club.defaultEventImages)) {
              Object.assign(club, { defaultEventImages: club.defaultEventImages?.[category] });
            }

            await createEvent({
              socketClientId,
              id,
              sportType,
              club:
                club?.clubName === null || club?.clubName === "TBA"
                  ? { ...club, location: location.value }
                  : club,
              sponsor,
              organizer: {
                id: organizer?.id,
                firstName: organizer?.firstName,
                lastName: organizer?.lastName,
                clientType: organizer?.clientType,
                companyName: organizer?.companyName,
                email,
                phone,
              },
              coordinator,
              // location,
              playerPackage: {
                en: {
                  title: playerPackageTitleEn,
                  summary: playerPackageSummaryEn,
                  description: playerPackageDescriptionEn,
                },
                ro: {
                  title: playerPackageTitle,
                  summary: playerPackageSummary,
                  description: playerPackageDescription,
                },
                tShirt,
              },
              category,
              image: {
                logo: logo,
                cover: cover,
                featured: featuredImg,
              },
              ...values,
              phases,
            })
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          initialValues,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <br />
            <Row>
              <Col xs={12}>
                {components.map(({ label, updateOnly, Component }) => {
                  if (updateOnly && !id) {
                    return ""
                  }
                  return (
                    <Card>
                      <CardBody>
                        <CardTitle>{label}</CardTitle>
                        <div>
                          <Component
                            {...{
                              errors,
                              eventId,
                              touched,
                              handleChange,
                              handleBlur,
                              initialValues,
                              values,
                              setFieldValue,
                              setFieldTouched,
                              settings: settings || {},
                              getEvents,
                              id,
                              image,
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  )
                })}
              </Col>
            </Row>
            <div className="d-flex justify-content-between">
              <div>
                <Button color="primary" className="mb-4" type="submit">
                  Submit
                </Button>{" "}
                <Button onClick={toggle} className="mb-4" type="button">
                  Cancel
                </Button>
                {id && (
                  <>
                    <Button
                      className="mb-4 ml-1"
                      type="button"
                      onClick={() =>
                        goTo(
                          `${process.env.REACT_APP_API_URL}/events/${id}/xls`,
                          `${name} Report`
                        )
                      }
                    >
                      Download report
                    </Button>
                    <Button
                      className="mb-4 ml-1"
                      type="button"
                      onClick={() =>
                        goTo(
                          `${process.env.REACT_APP_API_URL}/events/${id}/xls/club`,
                          `${name} Club Report`
                        )
                      }
                    >
                      Download report for clubs
                    </Button>
                    <Button
                      className="mb-4 ml-1"
                      type="button"
                      onClick={() =>
                        goTo(
                          `${process.env.REACT_APP_API_URL}/events/${id}/xls/draw`,
                          `${name} Lucky Draw Report`
                        )
                      }
                    >
                      Download lucky draw report
                    </Button>
                    {playerPackage?.tShirt && (
                      <Button
                        className="mb-4 ml-1"
                        type="button"
                        onClick={() =>
                          goTo(
                            `${process.env.REACT_APP_API_URL}/events/${id}/xls/tshirts`,
                            `${name} Tshirts Report`
                          )
                        }
                      >
                        Download tshirts report
                      </Button>
                    )}
                  </>
                )}
              </div>
              {id && (
                <p className="text-muted">
                  Created on{" "}
                  {moment(activeEvent?.createdAt).format("MMMM Do YYYY")}, by
                  admin{" "}
                  {activeEvent?.createdBy?.firstName +
                    " " +
                    activeEvent?.createdBy?.lastName +
                    " #" +
                    activeEvent?.createdBy?.userId}
                </p>
              )}
            </div>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Confirm</ModalHeader>
              <ModalBody>
                All information entered will be lost if you cancel the process.
                Are you sure?
              </ModalBody>
              <ModalFooter>
                <Link to="/events-management">
                  <Button color="primary">Yes</Button>
                </Link>{" "}
                <Button color="secondary" onClick={toggle}>
                  no
                </Button>
              </ModalFooter>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapStatetoProps = ({ session: { settings } = {} }) => ({ settings })

export default connect(mapStatetoProps, {
  getSession: actions.getSession,
})(Details)
