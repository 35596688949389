import { get, put, patch, post, del } from "helpers/api_helper"
import toastr from "toastr"

export const getPlayers = async (filter, options) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(filter, options)
    ).toString()
    response = await get(`/find-players/search?${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getPlayersLocations = async filter => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(filter)).toString()
    response = await get(`/accounts/locations?role=player&${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getPlayersList = async (filter, options) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(filter, options)
    ).toString()
    response = await get(`/accounts?role=player&${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getPlayerInfo = async id => {
  let response
  try {
    response = await get(`/accounts/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}

export const createPlayer = async data => {
  let response
  try {
    response = await post("/admin/accounts", data)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getPlayerTransactions = async (data, transactionsOptions) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(transactionsOptions)
    ).toString()
    response = await get(`/admin/accounts/${data}/transactions?${filters}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getPlayerWalletTransactions = async (data, options) => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(options)).toString()
    response = await get(
      `/admin/accounts/${data}/transactions?paymentType=virtualWallet&${filters}`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getMembershipList = async (data, options) => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(options)).toString()
    response = await get(
      `/admin/accounts/${data}/transactions?type=upgradeMembership,upgradeMembershipByAdmin&${filters}`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getPlayerActivity = async (data, options) => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(options)).toString()
    response = data && (await get(`logs/players?playerId=${data}&${filters}`))
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getLevelChange = async (data, options) => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(options)).toString()
    response =
      data &&
      (await get(
        `logs/players?playerId=${data}&type=updateGameLevel&${filters}`
      ))
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getPlayerEntriesapi = async (id, playerEntriesOptions) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(playerEntriesOptions)
    ).toString()
    response = id && (await get(`/accounts/${id}/events/joined?${filters}`))
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getPlayerHistoryEntriesapi = async (
  id,
  playerEntriesHistoryOptions
) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(playerEntriesHistoryOptions)
    ).toString()
    response = id && (await get(`/accounts/${id}/events/closed?${filters}`))
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updatePlayer = async (id, data) => {
  let response
  try {
    response = await put(`/admin/accounts/${id}`, data)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updateStatus = async (id, status) => {
  let response
  try {
    response = await patch(`/admin/accounts/${id}/status`, { status })
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updatePlayerWallet = async (id, amount) => {
  let response
  try {
    response = await patch(`/admin/accounts/${id}/wallet-balance`, amount)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updatePlayerMembership = async (id, membership) => {
  let response
  try {
    response = await patch(`/admin/accounts/${id}/membership`, membership)
  } catch (error) {
    response = error.response.data
  }

  return response
}

export const deletePlayer = async id => {
  let response
  try {
    response = await del(`/admin/accounts/${id}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updatePlayerLevel = async (id, data) => {
  let response
  try {
    response = await patch(`/admin/accounts/${id}/game-level`, data)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getPlayerRatings = async ({ id, page, limit }) => {
  let response
  try {
    response = await get(`/accounts/${id}/ratings?page=${page}&limit=${limit}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}
