import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { Row, Col, FormGroup, Label } from "reactstrap"
import { capitalize } from "lodash";

const SportType = ({
  errors,
  touched,
  setFieldValue,
  initialValues,
  setFieldTouched,
  settings: { activeSports = [] } = {},
}) => {
  const formatNoClubOrganizers = () => {
    let org = []

    org = organizers?.results?.map(x => ({
      label:
        x?.organizerInfo?.clientType === "company"
          ? x?.organizerInfo?.companyName
          : `${x?.firstName} ${x?.lastName}`,
      value: x,
    }))

    org?.unshift({
      label: "No organizer",
      value: { firstName: "No", lastName: "organizer" },
    })
    return org
  }

  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <Label className="mb-1">Select event sport type*</Label>
            <Select
              name="sportType"
              defaultValue={
                initialValues?.sportType && {
                  label: capitalize(initialValues?.sportType),
                  value: initialValues?.sportType,
                }
              }
              placeholder="Choose Sport Type"
              options={activeSports.map(sport => ({
                label: capitalize(sport),
                value: sport,
              }))}
              onChange={e => setFieldValue("sportType", e.value)}
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
            {errors &&
            errors["sportType"] &&
            touched &&
            touched["sportType"] ? (
              <div className="error">{errors["sportType"]}</div>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

const mapStatetoProps = ({}) => ({})
export default connect(mapStatetoProps, {
})(SportType)
