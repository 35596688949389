import Info from "./Info"
import EventPhase from "./EventsPhase"
import Technical from "./Technical"
import Package from "./Package"
import Presentation from "./Presentation"
import SportType from "./SportType";

const EventTabs = [
  { label: "Sport Type", Component: SportType },
  { label: "Contact information", Component: Info },
  { label: "Event Stages", Component: EventPhase, updateOnly: true },
  { label: "Technical information", Component: Technical },
  { label: "Player package", Component: Package },
  { label: "Presentation", Component: Presentation },
]

export default EventTabs
