import React, { useEffect, useState, useRef, useContext } from "react"
import { connect } from "react-redux"
import { Link, withRouter, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import { Container, Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getClubLeadInfo, getClubArchiveInfo } from "./actions"
import { createClub } from "./api"
import FormData from "./FormData"
import { store as socketStore } from "../../../components/Common/Socket/store"

const Clubs = props => {
  const {
    getClubLeadInfo,
    clubLeadInfo,
    getClubArchiveInfo,
    clubArchiveInfo,
    loading,
    match: { params },
    history,
    session,
  } = props

  const { settings } = session

  const [leads, setLeads] = useState({})
  const [archiveClubAlert, setArchiveClubAlert] = useState(false)
  const [archiveClubMerged, setArchiveClubMerged] = useState(false)

  useEffect(() => {
    if (!isEmpty(params.id)) {
      getClubLeadInfo(params.id)
    }
  }, [getClubLeadInfo, params.id])

  useEffect(() => {
    if (!isEmpty(clubLeadInfo)) {
      setLeads(clubLeadInfo)

      if (!isEmpty(params.id)) {
        getClubArchiveInfo({
          clubName: clubLeadInfo.clubName,
          email: clubLeadInfo.email,
          phone: clubLeadInfo.phone,
        })
      }
    }
  }, [clubLeadInfo, getClubArchiveInfo, params.id])

  //reset values
  useEffect(() => {
    setLeads({})
  }, [])

  useEffect(() => {
    if (!isEmpty(clubArchiveInfo)) {
      setArchiveClubAlert(true)
    }
  }, [clubArchiveInfo])

  //values for days
  const [openHours, setOpenHours] = useState({
    Mon: {},
    Tue: {},
    Wed: {},
    Thu: {},
    Fri: {},
    Sat: {},
    Sun: {},
  })
  const [isLoading, setIsLoading] = useState(false)
  const globalState = useContext(socketStore)
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message)
        setIsLoading(false)
        return
      }

      toastr.success(message)
      history.goBack()
    }

    if (socket) {
      socket.removeAllListeners("clubs-response")
      socket.on("clubs-response", listenForResponse)

      return () => {
        socket.removeAllListeners("clubs-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  const mergeClubData = () => {
    setLeads({ ...clubLeadInfo, ...clubArchiveInfo })
  }

  const handleSubmit = async (event, values) => {
    setIsLoading(true)

    // set location
    values.location = values?.location?.value
    if (values.organizerInfo) {
      values.organizerInfo.location = values?.organizerInfo?.location?.value
    }

    const response = await createClub({
      socketClientId,
      openHours: openHours,
      amenities: selectedAmenities,
      ...values,
    })

    if (!response.success) {
      toastr.error(response.message || "Club could not be created.")
      setIsLoading(false)
    }
  }

  const [selectedAmenities, setSelectedAmenities] = useState([])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            path="/accounts/clubs"
            title="User Accounts"
            breadcrumbItem="Create club"
          />
          {loading && params.id ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (
            <>
              <FormData
                openHours={openHours}
                setOpenHours={setOpenHours}
                handleSubmit={handleSubmit}
                clubInfo={leads}
                history={props.history}
                loading={isLoading}
                eligibleCountries={settings?.eligibleCountries}
                selectedAmenities={selectedAmenities}
                setSelectedAmenities={setSelectedAmenities}
                activeSports={settings?.activeSports}
                courtTypes={settings?.courtTypes}
              />

              {archiveClubMerged && (
                <SweetAlert
                  success
                  title="Success!"
                  onConfirm={() => {
                    setArchiveClubMerged(false)
                  }}
                >
                  <p>Data was successfully imported.</p>
                </SweetAlert>
              )}

              {clubArchiveInfo && archiveClubAlert && !isEmpty(params.id) && (
                <SweetAlert
                  title="New club data found."
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setArchiveClubAlert(false)
                    setArchiveClubMerged(true)
                    mergeClubData()
                  }}
                  onCancel={() => {
                    setArchiveClubAlert(false)
                  }}
                >
                  <table className="table table-dark text-left">
                    <tbody>
                      {clubArchiveInfo.businessName && (
                        <tr>
                          <td>Business name:</td>
                          <td>{clubArchiveInfo.businessName}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.clubName && (
                        <tr>
                          <td>Club name:</td>
                          <td>{clubArchiveInfo.clubName}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.sportType && (
                        <tr>
                          <td>Sport type:</td>
                          <td>{clubArchiveInfo.sportType}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.country && (
                        <tr>
                          <td>Country:</td>
                          <td>{clubArchiveInfo.country}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.county && (
                        <tr>
                          <td>County:</td>
                          <td>{clubArchiveInfo.county}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.city && (
                        <tr>
                          <td>City:</td>
                          <td>{clubArchiveInfo.city}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.postalCode && (
                        <tr>
                          <td>Postal code:</td>
                          <td>{clubArchiveInfo.postalCode}</td>
                        </tr>
                      )}

                      {clubArchiveInfo.ownerInfo && (
                        <tr>
                          <td>Owner info:</td>
                          <td>
                            <p>
                              {clubArchiveInfo.ownerInfo?.firstName}{" "}
                              {clubArchiveInfo.ownerInfo?.lastName}
                            </p>
                            <p>{clubArchiveInfo.ownerInfo?.email}</p>
                            <p>{clubArchiveInfo.ownerInfo?.phone}</p>
                          </td>
                        </tr>
                      )}

                      {clubArchiveInfo.contactInfo && (
                        <tr>
                          <td>Contact info:</td>
                          <td>
                            <p>
                              {clubArchiveInfo.contactInfo?.firstName}{" "}
                              {clubArchiveInfo.contactInfo?.lastName}
                            </p>
                            <p>{clubArchiveInfo.contactInfo?.email}</p>
                            <p>{clubArchiveInfo.contactInfo?.phone}</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <h5 className="mt-4">Merge with current club lead?</h5>
                </SweetAlert>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Clubs.propTypes = {
  clubLeadInfo: PropTypes.object,
  clubArchiveInfo: PropTypes.object,
  getClubLeadInfo: PropTypes.func,
  getClubArchiveInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ clubs, session }) => ({
  loading: clubs.loading,
  clubLeadInfo: clubs.clubLeadInfo,
  clubArchiveInfo: clubs.clubArchiveInfo,
  session: session,
})

const mapDispatchToProps = dispatch => ({
  getClubLeadInfo: id => dispatch(getClubLeadInfo(id)),
  getClubArchiveInfo: params => dispatch(getClubArchiveInfo(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Clubs))
