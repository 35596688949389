import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import moment from "moment"
import { isEmpty, capitalize } from "lodash"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavLink,
  NavItem,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
  FormGroup,
  Label,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import SweetAlert from "react-bootstrap-sweetalert"
import toastr from "toastr"
import classnames from "classnames"

import defaultProfilePicture from "../../../../assets/images/users/default-profile-picture.png"
import Breadcrumbs from "components/Common/Breadcrumb"
import RatingsModal from "./ratingsModal"
import activityColumns from "./activityColumns"
import entriesColumns from "./entriesColumns"
import membershipColumns from "./membershipColumns"
import orderColumns from "./orderColumns"
import levelChangeColumns from "./levelChangeColumns"
import {
  getPlayerInfo,
  getPlayerTransactions,
  getPlayerWalletTransactions,
  getMembershipList,
  getPlayerActivity,
  getLevelChange,
  getPlayerEntries,
  getPlayerHistoryEntries,
  getPlayerReferralRewards,
  getPlayerFirstTaskRewards,
} from "../actions"
import {
  updateStatus,
  updatePlayerLevel,
  updatePlayerWallet,
  updatePlayerMembership,
} from "../api"
import { getReports } from "../../../ReportsManagement/api"

import { store as socketStore } from "../../../../components/Common/Socket/store"
import { get, post } from "helpers/api_helper"

const PlayerProfile = props => {
  const {
    userId,
    getPlayerInfo,
    playerInfo,
    getPlayerTransactions,
    getPlayerWalletTransactions,
    getMembershipList,
    getPlayerActivity,
    getLevelChange,
    loading,
    playerTransactions,
    playerWalletTransactions,
    membershipList,
    playerActivity,
    levelChange,
    fetchPlayerEntries,
    playerEntries,
    fetchPlayerHistoryEntries,
    playerHistoryEntries,
    match: { params },
    settings,
    referralRewards,
    firstTaskRewards,
    fetchPlayerReferralRewards,
    fetchPlayerFirstTaskRewards,
  } = props

  const pageWalletTransactionsOptions = {
    page: playerWalletTransactions?.page,
    sizePerPage: playerWalletTransactions?.limit,
    totalSize: playerWalletTransactions?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const pageTransactionsOptions = {
    page: playerTransactions?.page,
    sizePerPage: playerTransactions?.limit,
    totalSize: playerTransactions?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const pageMembershipOptions = {
    page: membershipList?.page,
    sizePerPage: membershipList?.limit,
    totalSize: membershipList?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const pagePlayerActivityOptions = {
    page: playerActivity?.page,
    sizePerPage: playerActivity?.limit,
    totalSize: playerActivity?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const pageLevelChangeOptions = {
    page: levelChange?.page,
    sizePerPage: levelChange?.limit,
    totalSize: levelChange?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const pagePlayerEntriesOptions = {
    page: playerEntries?.page,
    sizePerPage: playerEntries?.limit,
    totalSize: playerEntries?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const pagePlayerHistoryEntriesOptions = {
    page: playerHistoryEntries?.page,
    sizePerPage: playerHistoryEntries?.limit,
    totalSize: playerHistoryEntries?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const initialOptions = {
    page: 1,
    limit: 10,
  }

  const [blockAlert, setBlockAlert] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [activateAlert, setActivateAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState()
  const [alertDescription, setAlertDescription] = useState()
  const [activeTab, setActiveTab] = useState("1")
  const [walletBalance, setWalletBalance] = useState(0)
  const [topUpAmmount, setTopUpAmmount] = useState(
    playerInfo?.walletBalance || 0
  )
  const [editMemberShip, setEditMemberShip] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId, socket } = {} } = {} } =
    globalState
  const [options, setOptions] = useState(initialOptions)
  const [transactionsOptions, setTransactionsOptions] = useState(initialOptions)
  const [membershipOptions, setMembershipOptions] = useState(initialOptions)
  const [playerActivityOptions, setPlayerActivityOptions] =
    useState(initialOptions)
  const [levelChangeOptions, setLevelChangeOptions] = useState(initialOptions)
  const [playerEntriesOptions, setPlayerEntriesOptions] =
    useState(initialOptions)
  const [playerHistoryEntriesOptions, setPlayerHistoryEntriesOptions] =
    useState(initialOptions)
  const [membershipToChoose, setMembershipToChoose] = useState(null)
  const [entriesTable, setEntriesTable] = useState("current")
  const [activeCompetitonRegisteredAlert, setActiveCompetitonRegisteredAlert] =
    useState(false)
  const [playerReports, setPlayerReports] = useState({})
  const [viewRatingsModal, setViewRatingsModal] = useState(false)

  const toggle = () => setViewRatingsModal(!viewRatingsModal)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleBlock = async () => {
    const result = await updateStatus(params.id, "blocked")

    if (result?.success) {
      setBlockAlert(false)
      setSuccessAlert(true)
      setAlertTitle("Blocked")
      setAlertDescription("Account was successfully blocked.")
      getPlayerInfo(params.id)
    } else {
      toastr.error(result?.message || "Player could not be blocked.")
    }
  }

  const handleUpdateMembership = async () => {
    setIsLoading(true)

    const response = await updatePlayerMembership(params.id, {
      socketClientId,
      membership: editMemberShip.value,
    })

    if (!response.success) {
      toastr.error(response.message || "Membership could not be updated.")
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  const handleDelete = async () => {
    const result = await updateStatus(params.id, "deleted")

    if (result?.success) {
      setDeleteAlert(false)
      setSuccessAlert(true)
      setAlertTitle("Deleted")
      setAlertDescription("Account was successfully deleted.")
      getPlayerInfo(params.id)
    } else {
      toastr.error(result?.message || "Player could not be deleted.")
    }
  }

  const handleActivate = async () => {
    const result = await updateStatus(params.id, "activated")

    if (result?.success) {
      setActivateAlert(false)
      setSuccessAlert(true)
      setAlertTitle("Activated")
      setAlertDescription("Account was successfully activated.")
      getPlayerInfo(params.id)
    } else {
      toastr.error(result?.message || "Player could not be activated.")
    }
  }

  const handleWalletBalance = event => {
    setWalletBalance(event.target.value)
    event.target.value === "custom"
      ? setTopUpAmmount(0)
      : setTopUpAmmount(parseFloat(event.target.value))
  }

  const handleCustomWalletBalance = event => {
    setTopUpAmmount(parseFloat(event.target.value))
  }

  const handleBalanceSubmit = async (event, values) => {
    setIsLoading(true)

    const response = await updatePlayerWallet(params.id, {
      socketClientId,
      amount: values.walletBalance,
      notes: values?.addNotes,
    })

    if (!response.success) {
      toastr.error(response.message || "Balance could not be updated.")
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  const handleLevelChange = async (event, values) => {
    setIsLoading(true)

    const response = await updatePlayerLevel(params.id, {
      socketClientId,
      sportType,
      gameLevel: {
        singles: selectedSoloLevel || playerInfo.sports?.[sportType]?.singles?.gameLevel,
        doubles: selectedDuoLevel || playerInfo.sports?.[sportType]?.doubles?.gameLevel,
      },
    })

    if (!response.success) {
      toastr.error(response.message || "Level could not be updated.")
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handleTransactionTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setTransactionsOptions({
          ...transactionsOptions,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handleActivityTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setPlayerActivityOptions({
          ...playerActivityOptions,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handleLevelChangeChange = (type, data) => {
    switch (type) {
      case "pagination":
        setLevelChangeOptions({
          ...levelChangeOptions,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handleMembershipTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setMembershipOptions({
          ...membershipOptions,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handlePlayerEntriesChange = (type, data) => {
    switch (type) {
      case "pagination":
        setPlayerEntriesOptions({
          ...playerEntriesOptions,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handlePlayerHistoryEntriesChange = (type, data) => {
    switch (type) {
      case "pagination":
        setPlayerHistoryEntriesOptions({
          ...playerHistoryEntriesOptions,
          page: data.page,
        })
        break
      default:
        break
    }
  }

  const handleChangeTable = e => {
    setEntriesTable(e)
  }

  useEffect(() => {
    getPlayerInfo(params.id)
  }, [getPlayerInfo, params.id])

  useEffect(() => {
    if (playerInfo?.userId) {
      getPlayerTransactions(playerInfo?.userId, transactionsOptions)
    }
    fetchPlayerEntries(playerInfo?.userId, playerEntriesOptions)
  }, [
    getPlayerTransactions,
    playerInfo,
    fetchPlayerEntries,
    transactionsOptions,
    playerEntriesOptions,
  ])

  useEffect(() => {
    fetchPlayerHistoryEntries(playerInfo?.userId, playerHistoryEntriesOptions)
  }, [playerInfo, fetchPlayerHistoryEntries, playerHistoryEntriesOptions])

  useEffect(() => {
    getPlayerWalletTransactions(playerInfo?.userId, options)
  }, [playerInfo, getPlayerWalletTransactions, options])

  useEffect(() => {
    if (playerInfo?.userId) {
      fetchPlayerReferralRewards(playerInfo?.userId)
    }
  }, [playerInfo, fetchPlayerReferralRewards])

  useEffect(() => {
    if (playerInfo?.userId) {
      fetchPlayerFirstTaskRewards(playerInfo?.userId)
    }
  }, [playerInfo, fetchPlayerFirstTaskRewards])

  useEffect(() => {
    getMembershipList(playerInfo?.userId, membershipOptions)
  }, [playerInfo, getMembershipList, membershipOptions])

  useEffect(() => {
    getPlayerActivity(playerInfo?.id, playerActivityOptions)
  }, [playerInfo, getPlayerActivity, playerActivityOptions])

  useEffect(() => {
    getLevelChange(playerInfo?.id, levelChangeOptions)
  }, [playerInfo, getLevelChange, levelChangeOptions])

  const [sportType, setSportType] = useState('tennis')
  const [selectedSoloLevel, setSelectedSoloLevel] = useState(playerInfo.sports?.[sportType]?.singles?.gameLevel)
  const [selectedDuoLevel, setSelectedDuoLevel] = useState(playerInfo.sports?.[sportType]?.doubles?.gameLevel)
  const [newsletter, setNewsletter] = useState(playerInfo?.communicationPreferences?.newsletter)

  useEffect(() => {
    if (!isEmpty(playerInfo) && sportType) {
      setSelectedSoloLevel(playerInfo.sports?.[sportType]?.singles?.gameLevel)
      setSelectedDuoLevel(playerInfo.sports?.[sportType]?.doubles?.gameLevel)
    }
  }, [playerInfo, sportType]);

  useEffect(() => {
    let response
    try {
      if (playerInfo?.userId) {
        response = get(`/accounts/mailchimp-sync?userId=${playerInfo.userId}`)

        if (
          playerInfo?.communicationPreferences?.newsletter &&
          response?.data?.communicationPreferences?.newsletter &&
          response?.data?.communicationPreferences?.newsletter !==
            playerInfo?.communicationPreferences?.newsletter
        ) {
          setNewsletter(response?.data?.communicationPreferences?.newsletter)
        }
      }
    } catch (error) {
      response = error.response
    }
  }, [playerInfo])

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message)
        setIsLoading(false)
        return
      }
      toastr.success(message)
      getPlayerInfo(params.id)
    }

    if (socket) {
      socket.removeAllListeners("accounts-response")
      socket.on("accounts-response", listenForResponse)

      return () => {
        socket.removeAllListeners("accounts-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  useEffect(() => {
    const membs = !!settings && [
      {
        label: "free",
        value: {
          id: null,
          plan: "free",
        },
      },
      ...settings?.memberships
        ?.sort((a, b) => a?.activeDays - b?.activeDays)
        ?.map((plan, key) => ({
          label: (
            <div key={key}>
              {plan?.name?.split("-")[0].charAt(0).toUpperCase() +
                plan?.name?.split("-")[0].slice(1) +
                " " +
                plan?.name?.split("-")[1] +
                " " +
                `(${plan?.activeDays} days)`}
            </div>
          ),
          value: plan,
        })),
    ]

    setMembershipToChoose(membs)
  }, [settings])

  useEffect(() => {
    async function getPlayerReports(reportedId) {
      const response = await getReports({ payload: [{ reportedId }] })
      setPlayerReports(response)
    }
    if (playerInfo?.userId) {
      getPlayerReports(playerInfo?.userId)
    }
  }, [playerInfo])

  const totalReferralRewards = (referralRewards.results || []).reduce((total, reward) => total + reward.amount, 0);
  const totalFirstTaskRewards = (firstTaskRewards.results || []).reduce((total, reward) => total + reward.amount, 0);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Players"
            path="/accounts/players"
            breadcrumbItem="Player Profile"
          />
          {loading || isLoading ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (
            <>
              <Row>
                {playerInfo?.status !== "deleted" && (
                  <Col xl="12">
                    <Card>
                      <CardBody className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0">
                          #{playerInfo.userId} - {playerInfo.firstName}{" "}
                          {playerInfo.lastName}
                        </h4>
                        <div>
                          <span
                            onClick={async () => {
                              try {
                                const {
                                  data: { impersonateKey },
                                } = await get("/admin/accounts/auth")
                                await post(
                                  `/accounts/auth?impersonate=true&userId=${userId}&hash=${impersonateKey}`,
                                  {
                                    email: playerInfo.email,
                                    password: "password",
                                  }
                                )

                                window.open(
                                  `${process.env.REACT_APP_WEB_URL}/accounts/${playerInfo.id}`
                                )
                              } catch {
                                console.log("error")
                              }
                            }}
                            // href={`${process.env.REACT_APP_WEB_URL}/accounts/impersonate/${playerInfo.id}`}
                            className="btn btn-secondary waves-effect waves-light btn-md ml-3"
                            target="_blank"
                          >
                            Impersonate
                          </span>
                          {playerInfo.status !== "blocked" && (
                            <Button
                              className="btn btn-warning waves-effect waves-light btn-md ml-3"
                              onClick={() => {
                                setBlockAlert(true)
                              }}
                            >
                              Block
                            </Button>
                          )}
                          {playerInfo.status !== "deleted" && (
                            <Button
                              className="btn btn-danger waves-effect waves-light btn-md ml-3"
                              onClick={() => {
                                setDeleteAlert(true)
                              }}
                            >
                              Delete
                            </Button>
                          )}
                          {playerInfo.status !== "activated" && (
                            <Button
                              className="btn btn-success waves-effect waves-light btn-md ml-3"
                              onClick={() => {
                                setActivateAlert(true)
                              }}
                            >
                              Activate
                            </Button>
                          )}
                          <Link
                            to={`/accounts/players/${playerInfo.id}/edit`}
                            className="btn btn-light waves-effect waves-light btn-md ml-3"
                          >
                            Edit
                          </Link>
                          {/* <Link
                        to={`/chat/${playerInfo.id}`}
                        className="btn btn-primary waves-effect waves-light btn-md ml-3"
                      >
                        Send Message
                      </Link> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xl="12">
                  <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                      <Row>
                        <Col xs="7">
                          <div className="text-primary p-3"></div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                          {/* <img src={profile1} alt="" className="img-fluid" /> */}
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="3">
                          <div className="avatar-lg profile-user-wid mb-4">
                            <img
                              src={`${
                                playerInfo.profilePicture
                                  ? `${process.env.REACT_APP_API_URL}/file/${playerInfo.profilePicture}`
                                  : defaultProfilePicture
                              }`}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {playerInfo.firstName} {playerInfo.lastName}
                          </h5>
                        </Col>
                        <Col sm={9}>
                          <div className="pt-4">
                            <Row>
                              <Col xs="3">
                                <h5 className="font-size-15">Role</h5>
                                <p className="text-muted mb-0 text-uppercase">
                                  {playerInfo.role}
                                </p>
                              </Col>
                              <Col xs="3">
                                <h5 className="font-size-15">Status</h5>
                                <p className="text-muted mb-0 text-uppercase">
                                  {playerInfo.status}
                                </p>
                              </Col>
                              <Col xs="3">
                                <h5 className="font-size-15">Membership</h5>
                                <p className="text-muted mb-0 text-uppercase">
                                  {playerInfo.membership?.plan}
                                </p>
                              </Col>
                              <Col xs="3">
                                <h5 className="font-size-15">Balance</h5>
                                <p className="text-muted mb-0 text-uppercase">
                                  {playerInfo.walletBalance +
                                    " " +
                                    playerInfo.currency}
                                </p>
                              </Col>
                              <Col className="mt-1" xs="3">
                                <h5 className="font-size-15">Language</h5>
                                <p className="text-muted mb-0 text-uppercase">
                                  {playerInfo?.language}
                                </p>
                              </Col>
                              <Col className="mt-1" xs="3">
                                <h5 className="font-size-15">Newsletter</h5>
                                <p className="text-muted mb-0 text-uppercase">
                                  {newsletter ? "Yes" : "No"}
                                </p>
                              </Col>
                              <Col className="mt-1" xs="3">
                                <h5 className="font-size-15">Reported</h5>
                                <p className="text-muted mb-0">
                                  {playerReports?.data?.length ? (
                                    <Link
                                      to={`/reports-management?reportedId=${playerInfo?.userId}`}
                                    >
                                      {playerReports?.data?.length} times
                                    </Link>
                                  ) : (
                                    "0 times"
                                  )}
                                </p>
                              </Col>
                              <Col className="mt-1" xs="3">
                                <h5 className="font-size-15">Player Rating</h5>
                                <p className="text-muted mb-0">
                                  {playerInfo?.ratingInfo?.avgRating ?? 0}{" "}
                                  <i className="bx bxs-star"></i> -{" "}
                                  <span
                                    className="text-primary pointer"
                                    onClick={() => toggle()}
                                  >
                                    {playerInfo?.ratingInfo?.totalRatings ?? 0}{" "}
                                    active ratings
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={8}>
                          <p className="text-muted mb-0 text-truncate">
                            E-mail:{" "}
                            <a href={"mailto:" + playerInfo.email}>
                              {playerInfo.email}
                            </a>
                          </p>
                          <p className="text-muted mb-0 text-truncate">
                            Is organizer:{" "}
                            {playerInfo.isOrganizer ? "Yes" : "No"}
                          </p>
                        </Col>
                        <Col sm={4} className="text-right">
                          <div className="mt-1">
                            <a
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              href={`${process.env.REACT_APP_WEB_URL}/accounts/${playerInfo.id}`}
                              target="_blank"
                            >
                              View Profile{" "}
                              <i className="mdi mdi-arrow-right ms-1" />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row" style={{ maxWidth: "80px" }}>
                                Total rewards:
                              </th>
                              <td>
                                {`${
                                  totalReferralRewards + totalFirstTaskRewards
                                }${
                                  firstTaskRewards.results?.[0]?.currency ??
                                  referralRewards.results?.[0]?.currency ??
                                  ""
                                }`}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ maxWidth: "80px" }}>
                                Own rewards:
                              </th>
                              <td>
                                {`${totalFirstTaskRewards}${
                                  firstTaskRewards.results?.[0]?.currency ?? ""
                                }`}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ maxWidth: "80px" }}>
                                Invitation rewards (
                                {referralRewards.results?.length ?? 0}/
                                {settings?.rewardsInfo?.currencies?.[
                                  playerInfo?.currency
                                ]?.maxRewards ??
                                  settings?.rewardsInfo?.maxRewards}
                                ):
                              </th>
                              <td>
                                {`${totalReferralRewards}${
                                  referralRewards.results?.[0]?.currency ?? ""
                                }`}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ maxWidth: "80px" }}>
                                Network size:
                              </th>
                              <td>
                                {playerInfo.totalReferredAccounts} players
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Birth date:</th>
                              <td>
                                {moment(playerInfo.birthDate).format(
                                  "D MMM YYYY"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Location:</th>
                              <td>{playerInfo.location?.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Phone:</th>
                              <td>{playerInfo.phone}</td>
                            </tr>
                            <tr>
                              <th scope="row">Gender:</th>
                              <td className="text-capitalize">
                                {playerInfo.gender}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Dominant Hand:</th>
                              <td>{playerInfo.dominantHand}</td>
                            </tr>
                            <tr>
                              <th scope="row">T-shirt size:</th>
                              <td>{playerInfo?.tShirtSize?.toUpperCase()}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        Game Levels, Rankings & Points
                      </CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th>Sport</th>
                              <th>Game Level</th>
                              <th>Rank</th>
                              <th>Points</th>
                            </tr>
                          </thead>
                          <tbody>
                          {(settings?.activeSports || []).map((sport) => (
                            <tr>
                              <td scope="row">{capitalize(sport)}</td>
                              <td>Singles: {playerInfo?.sports?.[sport]?.singles?.gameLevel ?? "-"}, Doubles: {playerInfo?.sports?.[sport]?.doubles?.gameLevel ?? "-"}</td>
                              <td>Singles: {playerInfo?.sports?.[sport]?.singles?.rank ?? "-"}, Doubles: {playerInfo?.sports?.[sport]?.doubles?.rank ?? "-"}</td>
                              <td>Singles: {playerInfo?.sports?.[sport]?.singles?.points ?? "-"}, Doubles: {playerInfo?.sports?.[sport]?.doubles?.points ?? "-"}</td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Availability</CardTitle>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Start interval</th>
                              <th>End interval</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playerInfo.availability
                              ? Object?.keys(playerInfo.availability)?.map(
                                  item => (
                                    <tr key={item}>
                                      <th className="text-capitalize">
                                        {item}
                                      </th>
                                      <td>
                                        {playerInfo?.availability[item]
                                          ?.startDate
                                          ? moment(
                                              playerInfo?.availability[item]
                                                ?.startDate
                                            ).format("hh:mm")
                                          : "8:00"}
                                      </td>
                                      <td>
                                        {playerInfo?.availability[item]?.endDate
                                          ? moment(
                                              playerInfo?.availability[item]
                                                ?.endDate
                                            ).format("hh:mm")
                                          : "22:00"}{" "}
                                      </td>
                                    </tr>
                                  )
                                )
                              : ""}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Player activity
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Tournament entries
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Membership
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              toggleTab("4")
                            }}
                          >
                            <span className="d-none d-sm-block">Orders</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              toggleTab("5")
                            }}
                          >
                            <span className="d-none d-sm-block">Wallet</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "6",
                            })}
                            onClick={() => {
                              toggleTab("6")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              Level change
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="pt-3">
                          <PaginationProvider
                            pagination={paginationFactory(
                              pagePlayerActivityOptions
                            )}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={
                                  isEmpty(playerActivity)
                                    ? []
                                    : playerActivity?.results
                                }
                                columns={activityColumns(
                                  isEmpty(playerActivity)
                                    ? []
                                    : playerActivity?.results
                                )}
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            remote
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table table-centered table-hover table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={
                                              handleActivityTableChange
                                            }
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </TabPane>
                        <TabPane tabId="2" className="pt-3">
                          <div className="mb-2 d-flex justify-content-end">
                            <div style={{ width: "200px" }}>
                              <Select
                                defaultValue={
                                  entriesTable === "current"
                                    ? {
                                        label: "Current entries",
                                        value: "current",
                                      }
                                    : {
                                        label: "History entries",
                                        value: "history",
                                      }
                                }
                                onChange={e => handleChangeTable(e.value)}
                                options={[
                                  {
                                    label: "Current entries",
                                    value: "current",
                                  },
                                  {
                                    label: "History entries",
                                    value: "history",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                          <PaginationProvider
                            pagination={paginationFactory(
                              entriesTable === "current"
                                ? pagePlayerEntriesOptions
                                : pagePlayerHistoryEntriesOptions
                            )}
                          >
                            {({ paginationProps, paginationTableProps }) => {
                              const getAllEntries = playerEntries => {
                                let allEntries = []

                                playerEntries?.results?.map(event => {
                                  event?.competitions?.map(competition => {
                                    const newCompetition = {
                                      ...competition,
                                      name: event?.name,
                                      category: event?.category,
                                      eventId: event?._id,
                                      sportType: event?.sportType,
                                    }
                                    allEntries?.push(newCompetition)
                                  })
                                })

                                return allEntries
                              }

                              return (
                                <ToolkitProvider
                                  keyField="id"
                                  data={
                                    entriesTable === "current"
                                      ? getAllEntries(playerEntries)
                                      : getAllEntries(playerHistoryEntries)
                                  }
                                  columns={entriesColumns(playerInfo?.userId)}
                                  bootstrap4
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              responsive
                                              remote
                                              bordered={false}
                                              striped={false}
                                              classes={
                                                "table table-centered table-hover table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              onTableChange={
                                                entriesTable === "current"
                                                  ? handlePlayerEntriesChange
                                                  : handlePlayerHistoryEntriesChange
                                              }
                                              {...paginationTableProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-md-center mt-30">
                                        <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )
                            }}
                          </PaginationProvider>
                        </TabPane>
                        {/* membership tab */}
                        <TabPane tabId="3" className="pt-3">
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-3 border-bottom pb-2">
                                Change player membership
                              </CardTitle>
                              <Row className="mb-3">
                                <Col md="12">
                                  <Label>Add/update membership</Label>

                                  <Select
                                    name="membershipEdit"
                                    onChange={e => setEditMemberShip(e)}
                                    options={membershipToChoose}
                                    defaultValue={
                                      playerInfo.membership?.plan === "free"
                                        ? {
                                            label: <div>free</div>,
                                            value: "free",
                                          }
                                        : settings?.memberships
                                            ?.filter(
                                              option =>
                                                option.type ===
                                                playerInfo.membership?.plan
                                            )
                                            ?.map(plan => ({
                                              label: (
                                                <div>
                                                  {plan?.name
                                                    ?.split("-")[0]
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    plan?.name
                                                      ?.split("-")[0]
                                                      .slice(1) +
                                                    " " +
                                                    plan?.name?.split("-")[1] +
                                                    " " +
                                                    `(${plan?.activeDays} days)`}
                                                </div>
                                              ),
                                              value: plan,
                                            }))
                                    }
                                  />
                                </Col>
                              </Row>
                              <Button
                                onClick={handleUpdateMembership}
                                color="primary"
                              >
                                Set new membership
                              </Button>
                            </CardBody>
                          </Card>
                          <PaginationProvider
                            pagination={paginationFactory(
                              pageMembershipOptions
                            )}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={membershipList?.results || []}
                                columns={membershipColumns(membershipList)}
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        {playerInfo?.membership?.plan !==
                                        "free" ? (
                                          <p>
                                            Premium is valid until{" "}
                                            {moment
                                              .utc(
                                                playerInfo?.membership?.endDate
                                              )
                                              .format("YYYY-MM-DD HH:mm")}
                                          </p>
                                        ) : (
                                          ""
                                        )}

                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            remote
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table table-centered table-hover table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={
                                              handleMembershipTableChange
                                            }
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </TabPane>
                        {/* orders */}
                        <TabPane tabId="4" className="pt-3">
                          <PaginationProvider
                            pagination={paginationFactory(
                              pageTransactionsOptions
                            )}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={playerTransactions?.results || []}
                                columns={orderColumns(playerTransactions)}
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            remote
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table table-centered table-hover table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={
                                              handleTransactionTableChange
                                            }
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </TabPane>
                        {/* orders */}
                        <TabPane tabId="5" className="pt-3">
                          <AvForm onValidSubmit={handleBalanceSubmit}>
                            <Card>
                              <CardBody>
                                <CardTitle className="mb-3 border-bottom pb-2">
                                  <>
                                    Balance:{" "}
                                    {playerInfo?.walletBalance +
                                      " " +
                                      playerInfo.currency}
                                  </>
                                </CardTitle>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <AvField
                                        type="select"
                                        name="selectWalletBalance"
                                        label="Add Balance"
                                        value={walletBalance}
                                        onChange={handleWalletBalance}
                                      >
                                        <option selected value={0}>
                                          -
                                        </option>
                                        {settings?.topUpOptions?.map(
                                          (x, key) => {
                                            return (
                                              <option
                                                value={
                                                  x?.amount?.[
                                                    playerInfo.currency
                                                  ]
                                                }
                                                key={key}
                                              >
                                                {
                                                  x?.amount?.[
                                                    playerInfo.currency
                                                  ]
                                                }{" "}
                                                {playerInfo.currency}
                                              </option>
                                            )
                                          }
                                        )}
                                        <option value={"custom"}>
                                          Custom Amount
                                        </option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    {walletBalance === "custom" && (
                                      <>
                                        <AvField
                                          name="customWalletBalance"
                                          label="Custom Balance"
                                          placeholder="Enter Amount"
                                          type="text"
                                          errorMessage="Enter Amount"
                                          onChange={handleCustomWalletBalance}
                                          validate={{
                                            required: { value: true },
                                            pattern: {
                                              value:
                                                "^(-?0[.]\\d+)$|^(-?[1-9]+\\d*([.]\\d+)?)$|^0$",
                                              errorMessage: "Only Numbers",
                                            },
                                          }}
                                        />
                                        <span>
                                          Add pv (ammount positive) OR
                                          Subtraction pv (ammount negative)
                                        </span>
                                      </>
                                    )}
                                  </Col>
                                  <AvField
                                    type="hidden"
                                    name="walletBalance"
                                    value={topUpAmmount}
                                  />
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <AvField
                                        name="addNotes"
                                        label="Add Notes"
                                        placeholder="Add Notes"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="mr-1"
                                  disabled={loading}
                                >
                                  Add Balance
                                </Button>
                              </CardBody>
                            </Card>
                          </AvForm>

                          <PaginationProvider
                            pagination={paginationFactory(
                              pageWalletTransactionsOptions
                            )}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={playerWalletTransactions?.results || []}
                                columns={orderColumns(
                                  playerWalletTransactions,
                                  true // to hide PM Column
                                )}
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            remote
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table table-centered table-hover table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={handleTableChange}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </TabPane>

                        {/* PLAYER LEVEL HISTORY */}
                        <TabPane tabId="6" className="pt-3">
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-3 border-bottom pb-2">
                                Change player level
                              </CardTitle>
                              <Row className="mb-3">
                                <Col md="4">
                                  <Label>Sport Type</Label>
                                  <Select
                                    value={{
                                      label: capitalize(sportType),
                                      value: sportType,
                                    }}
                                    onChange={e => setSportType(e.value)}
                                    options={settings?.activeSports?.map(
                                      sport => ({
                                        label: capitalize(sport),
                                        value: sport,
                                      })
                                    )}
                                  />
                                </Col>
                                <Col md="4">
                                  <Label>Level Single</Label>
                                  <Select
                                    value={{
                                      label: selectedSoloLevel,
                                      value: selectedSoloLevel,
                                    }}
                                    onChange={e =>
                                      setSelectedSoloLevel(e.value)
                                    }
                                    options={settings?.levels?.map(x => ({
                                      label: x?.key,
                                      value: x?.key,
                                    }))}
                                  />
                                </Col>
                                <Col md="4">
                                  <Label>Level Double</Label>
                                  <Select
                                    value={{
                                      label: selectedDuoLevel,
                                      value: selectedDuoLevel,
                                    }}
                                    onChange={e => setSelectedDuoLevel(e.value)}
                                    options={settings?.levels?.map(x => ({
                                      label: x?.key,
                                      value: x?.key,
                                    }))}
                                  />
                                </Col>
                              </Row>
                              <Button
                                onClick={() => {
                                  playerEntries?.results.length > 0
                                    ? setActiveCompetitonRegisteredAlert(true)
                                    : handleLevelChange()
                                }}
                                color="primary"
                              >
                                Set new level
                              </Button>
                            </CardBody>
                          </Card>
                          <PaginationProvider
                            pagination={paginationFactory(
                              pageLevelChangeOptions
                            )}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={
                                  isEmpty(levelChange)
                                    ? []
                                    : levelChange?.results
                                }
                                columns={levelChangeColumns(
                                  isEmpty(levelChange)
                                    ? []
                                    : levelChange?.results
                                )}
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            remote
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table table-centered table-hover table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            onTableChange={
                                              handleLevelChangeChange
                                            }
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <RatingsModal
            playerId={playerInfo?.userId}
            viewRatingsModal={viewRatingsModal}
            toggle={toggle}
          />
        </Container>

        {activeCompetitonRegisteredAlert ? (
          <SweetAlert
            warning
            title="Level change"
            showCancel
            onConfirm={() => {
              handleLevelChange()
              setActiveCompetitonRegisteredAlert(false)
            }}
            onCancel={() => {
              setActiveCompetitonRegisteredAlert(false)
            }}
          >
            <h5>
              This player is registered to active competitions. Are you sure you
              want to change its level?
            </h5>
          </SweetAlert>
        ) : null}

        {successAlert ? (
          <SweetAlert
            success
            title={alertTitle || "Success"}
            onConfirm={() => {
              setSuccessAlert(false)
            }}
          >
            {alertDescription}
          </SweetAlert>
        ) : null}

        {blockAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, block it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleBlock}
            onCancel={() => setBlockAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {deleteAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, block it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleDelete}
            onCancel={() => setDeleteAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {activateAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, activate it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleActivate}
            onCancel={() => setActivateAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}

PlayerProfile.propTypes = {
  playerInfo: PropTypes.object,
  getPlayerInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({
  players,
  session,
  session: { userDetails } = {},
}) => ({
  loading: players.loading,
  playerInfo: players.playerInfo,
  playerTransactions: players.playerTransactions,
  referralRewards: players.referralRewards,
  firstTaskRewards: players.firstTaskRewards,
  playerWalletTransactions: players.playerWalletTransactions,
  membershipList: players.membershipList,
  playerActivity: players.playerActivity,
  levelChange: players.levelChange,
  memberships: session?.settings?.memberships,
  playerEntries: players?.playerEntries,
  playerHistoryEntries: players?.playerHistoryEntries,
  settings: session.settings,
  userId: userDetails.userId,
  impersonateKey: userDetails.impersonateKey,
})

const mapDispatchToProps = dispatch => ({
  getPlayerInfo: id => dispatch(getPlayerInfo(id)),
  getPlayerTransactions: (id, transactionsOptions) =>
    dispatch(getPlayerTransactions(id, transactionsOptions)),
  getPlayerWalletTransactions: (id, options) =>
    dispatch(getPlayerWalletTransactions(id, options)),
  getMembershipList: (id, membershipOptions) =>
    dispatch(getMembershipList(id, membershipOptions)),
  getPlayerActivity: (id, playerActivityOptions) =>
    dispatch(getPlayerActivity(id, playerActivityOptions)),
  getLevelChange: (id, levelChangeOptions) =>
    dispatch(getLevelChange(id, levelChangeOptions)),
  fetchPlayerEntries: (id, playerEntriesOptions) =>
    dispatch(getPlayerEntries(id, playerEntriesOptions)),
  fetchPlayerHistoryEntries: (id, playerHistoryEntriesOptions) =>
    dispatch(getPlayerHistoryEntries(id, playerHistoryEntriesOptions)),
  fetchPlayerReferralRewards: (id) =>
    dispatch(getPlayerReferralRewards(id)),
  fetchPlayerFirstTaskRewards: (id) =>
    dispatch(getPlayerFirstTaskRewards(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlayerProfile))
