import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { FormGroup, Label, Row, Col } from "reactstrap"
import Select from "react-select"
import Input from "../../components/Common/Input";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import debounce from "debounce-promise";
import { capitalize } from "lodash"

const sizeOptions = [
  { label: 'Singles', value: 'singles' },
  { label: 'Doubles', value: 'doubles' }
];

const typeOptions = [
  { label: 'Outdoor', value: 'outdoor' },
  { label: 'Indoor', value: 'indoor' }
];

const surfaceOptions = [
  { label: 'Grass', value: 'grass' },
  { label: 'Clay', value: 'clay' },
  { label: 'Hard', value: 'hard' },
  { label: 'Artificial', value: 'artificial' },
];

const bookingStatuses = [
  { label: 'Pending', value: 'pending' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Canceled', value: 'canceled' },
];

const paymentStatuses = [
  { label: 'Pending', value: 'pending' },
  { label: 'Success', value: 'success' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Refunded', value: 'refunded' },
];

const bookingTypes = [
  { label: 'Player Booking', value: 'playerBooking' },
  { label: 'Internal Booking', value: 'internalBooking' },
  { label: 'Free Slot', value: 'freeSlot' },
];

const Filters = ({ settings, filters, setFilters }) => {
  const [dateRange, setDateRange] = useState([]);
  const [startDate, endDate] = dateRange;

  const activeSports = (settings?.activeSports || [])
    ?.map((sport) => ({ label: capitalize(sport), value: sport }))

  useEffect(() => {
    if (startDate && endDate) {
      const data = `${moment(startDate).format( 'YYYY-MM-DD')}, ${moment(endDate).format( 'YYYY-MM-DD')}`
      handleFilters(data, 'dateRange');
    }
    if (startDate === null && endDate === null) {
      handleFilters(null, 'dateRange');
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  function handleFilters(data, element) {
    if (data) {
      setFilters({ ...filters, [element]: data })
    } else {
      const updatedFilters = { ...filters }

      delete updatedFilters[element]

      setFilters(updatedFilters)
    }
  }

  const debounceHandled = debounce(handleFilters, 300);

  return (
    <div className="w-100">
      {settings && (
        <Row className="w-100">
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Date Range</Label>
              <div class="datepicker-wrapper">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  onChange={setDateRange}
                  isClearable
                  dateFormat="dd MMM"
                  placeholderText="N/A"
                />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Sport Type</Label>
              <Select
                placeholder="All"
                name="sportType"
                onChange={(e) => handleFilters(e?.value, 'sportType')}
                options={activeSports}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Court Size</Label>
              <Select
                placeholder="All"
                name="courtSize"
                onChange={(e) => handleFilters(e?.value, 'courtSize')}
                options={sizeOptions}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Court Type</Label>
              <Select
                placeholder="All"
                name="courtType"
                onChange={(e) => handleFilters(e?.value, 'courtType')}
                options={typeOptions}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Court Surface</Label>
              <Select
                placeholder="All"
                name="courtSurface"
                onChange={(e) => handleFilters(e?.value, 'courtSurface')}
                options={surfaceOptions}
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Price Range</Label>
              <div className="flex gap-10">
                <Input
                  name="priceFrom"
                  placeholder="N/A"
                  onChange={(e) => debounceHandled(e?.target?.value, 'priceFrom')}
                />
                <Input
                  name="priceTo"
                  placeholder="N/A"
                  onChange={(e) => debounceHandled(e?.target?.value, 'priceTo')}
                />
              </div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Booking Status</Label>
              <Select
                name="bookingStatus"
                placeholder="All"
                onChange={(e) => handleFilters(e?.value, 'bookingStatus')}
                options={bookingStatuses}
                label="bookingStatus"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Payment Status</Label>
              <Select
                name="paymentStatus"
                placeholder="All"
                onChange={(e) => handleFilters(e?.value, 'paymentStatus')}
                options={paymentStatuses}
                label="paymentStatus"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Booking Type</Label>
              <Select
                name="bookingType"
                placeholder="All"
                onChange={(e) => handleFilters(e?.value, 'bookingType')}
                options={bookingTypes}
                label="bookingType"
                isClearable={true}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  )
}

const mapStateToProps = ({ session: { settings } = {} }) => ({ settings })

export default connect(mapStateToProps, null)(Filters)
