import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import toastr from "toastr"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Table,
  Label,
  FormGroup
} from "reactstrap";
import moment from "moment"

import defaultProfilePicture from "../../../assets/images/users/default-profile-picture.png"

import Breadcrumbs from "components/Common/Breadcrumb"

import { getBookingDetails, updateBookingDetails } from "../actions";
import { cancelBooking, rescheduleBooking } from "../api";

import { store as socketStore } from "../../../components/Common/Socket/store"
import timeToString from "../../../helpers/timeToString";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Input from "../../../components/Common/Input";

const reservationTypes = [
  { label: 'Club Booking', value: 'clubBooking' },
  { label: 'Club Event', value: 'clubEvent' },
  { label: 'Sportya Event', value: 'sportyaEvent' },
  { label: 'Class', value: 'class' },
  { label: 'Maintenance', value: 'maintenance' },
  { label: 'Other', value: 'other' },
];

const BookingDetails = props => {
  const {
    bookingDetails,
    getBookingDetails,
    updateBookingDetails,
    loading,
    match: { params },
  } = props

  const [cancelAlert, setCancelAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState()
  const [alertDescription, setAlertDescription] = useState()
  const [editData, setEditData] = useState(false)
  const [issueRefund, setIssueRefund] = useState(false);
  const [editPayload, setEditPayload] = useState({})

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId, socket } = {} } = {} } = globalState;

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      const toastrType = success ? 'success' : 'error';
      toastr?.[toastrType](message);

      // update booking
      updateBookingDetails(data);

      setCancelAlert(false);
      setEditData(false);
    }

    if (socket) {
      socket.removeAllListeners("clubs-response")
      socket.on("clubs-response", listenForResponse)

      return () => {
        socket.removeAllListeners("clubs-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  useEffect(() => {
    if (params?.id) {
      getBookingDetails(params.id)
    }
  }, [getBookingDetails, params.id])

  const handleCancel = async () => {
    const result = await cancelBooking(params.id, { issueRefund, socketClientId });

    if (!result?.success) {
      toastr.error(result?.message || "Booking could not be canceled.");
      setCancelAlert(false);
    }
  }

  const handleEdit = async () => {
    const result = await rescheduleBooking(params.id, { ...editPayload, socketClientId });

    if (!result?.success) {
      toastr.error(result?.message || "Booking could not be rescheduled.");
      setEditData(false);
    }
  }

  const {
    clubInfo = {},
    courtInfo = {},
    userInfo = {},
    paymentInfo = {}
  } = bookingDetails || {};

  const { bookingSettings } = clubInfo;

  let cancellationType;
  const now = new Date();

  if (bookingSettings) {
    const maxAllowedCancellationDate = new Date(bookingDetails?.startDate);
    const [hour, minute] = timeToString(bookingDetails?.startHour).split(':');
    maxAllowedCancellationDate.setHours(Number(hour), Number(minute));
    maxAllowedCancellationDate.setHours(maxAllowedCancellationDate.getHours() - bookingSettings?.minCancellationTime);

    cancellationType = now.getTime() < maxAllowedCancellationDate.getTime() ? 'early' : 'late';
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            path="/bookings-management"
            title="Bookings"
            breadcrumbItem={`Booking #${bookingDetails?.bookingId}`}
          />

          {loading || !bookingDetails ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (<>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody className="d-flex justify-content-between align-items-center" style={{minHeight: '72px', padding: '5px 20px'}}>
                    <h6 className="mb-0">Created At: {moment(bookingDetails.createdAt).format("MMMM D, YYYY, H:mm:ss")}</h6>
                    <div>
                      {!editData && bookingDetails.status !== "canceled" && (
                        <Button
                          className="btn waves-effect waves-light btn-md ml-3"
                          onClick={() => {
                            setEditPayload({
                              startDate: moment(bookingDetails?.startDate).format("YYYY-MM-DD"),
                              startHour: timeToString(bookingDetails?.startHour),
                              internalBooking: bookingDetails.internalBooking,
                            });
                            setEditData(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}

                      {bookingDetails.status !== "canceled" && (
                        <Button
                          className="btn btn-danger waves-effect waves-light btn-md ml-3"
                          onClick={() => {
                            setCancelAlert(true)
                          }}
                        >
                          Cancel Booking
                        </Button>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col xs="12">
                        <h5 className="p-3 text-center m-0">Customer Info</h5>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="3">
                        <div className="avatar-lg profile-user-wid mb-4">
                          <img
                            src={`${
                              userInfo?.profilePicture
                                ? `${process.env.REACT_APP_API_URL}/file/${userInfo?.profilePicture}`
                                : defaultProfilePicture
                            }`}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                      </Col>

                      <Col sm={9}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="2">
                              <h5 className="font-size-15">User ID</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {userInfo?.userId}
                              </p>
                            </Col>
                            <Col xs="3">
                              <h5 className="font-size-15">Email</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {userInfo?.email}
                              </p>
                            </Col>
                            <Col xs="3">
                              <h5 className="font-size-15">Phone</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {userInfo?.phone}
                              </p>
                            </Col>
                            <Col xs="4">
                              <h5 className="font-size-15">Location</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {userInfo?.location?.name}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <div className="font-size-15 mb-1">
                          <span className="mb-0 text-truncate">
                            Customer Name
                          </span>
                          <p className="font-weight-bold">{userInfo?.firstName} {userInfo?.lastName}</p>
                        </div>
                      </Col>
                      <Col sm={4} className="text-right">
                        <div className="mt-0">
                          <a
                            className="btn btn-primary waves-effect waves-light btn-sm"
                            href={`${process.env.REACT_APP_WEB_URL}/accounts/${userInfo.id}`}
                            target="_blank"
                          >
                            View Profile <i className="mdi mdi-arrow-right ms-1" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Booking Info</CardTitle>
                    <Table className="table-nowrap mb-0">
                      <tbody>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>Reservation Type: </th>
                        <td>
                          {!bookingDetails.internalBooking?.type && 'Player Booking'}
                          {!editData && bookingDetails.internalBooking?.type && `Internal Booking (${bookingDetails.internalBooking?.type})`}
                          {editData && bookingDetails.internalBooking?.type && (
                            <Select
                              placeholder="Reservation type"
                              name="reservationType"
                              onChange={({ value }) => setEditPayload({
                                ...editPayload,
                                internalBooking: { ...editPayload.internalBooking, type: value }
                              })}
                              options={reservationTypes}
                              defaultValue={reservationTypes.filter(( { value }) => value === editPayload?.internalBooking?.type ).shift()}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>Start Date: </th>
                        <td width="50%">
                          {!editData && moment(bookingDetails?.startDate).format("YYYY-MM-DD")}
                          {editData && (
                            <div className="datepicker-wrapper">
                              <DatePicker
                                startDate={new Date(bookingDetails?.startDate)}
                                selected={new Date(editPayload?.startDate)}
                                onChange={(startDate) => setEditPayload({ ...editPayload, startDate: moment(startDate).format("YYYY-MM-DD") })}
                                dateFormat="dd MMM YYYY"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>Start Hour: </th>
                        <td>
                          {!editData && timeToString(bookingDetails?.startHour)}
                          {editData && (
                            <div className="datepicker-wrapper">
                              <DatePicker
                                startDate={new Date(`${moment(bookingDetails?.startDate).format("YYYY-MM-DD")} ${timeToString(bookingDetails?.startHour)}`)}
                                selected={new Date(`${moment(editPayload?.startDate).format("YYYY-MM-DD")} ${editPayload?.startHour}`)}
                                onChange={(date) => setEditPayload({
                                  ...editPayload,
                                  startHour: moment(date).format("HH:mm")
                                })}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>Duration: </th>
                        <td>{bookingDetails?.duration} min</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>End Hour: </th>
                        <td>{timeToString(bookingDetails?.endHour)}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>Amount: </th>
                        <td>{bookingDetails?.amount} {bookingDetails.currency}</td>
                      </tr>
                      <tr>
                        <th scope="row" style={{width: '480px'}}>Booking Status: </th>
                        <td><span className="text-uppercase">{bookingDetails?.status}</span></td>
                      </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>


                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Payment Info</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Payment ID: </th>
                          <td>{paymentInfo?.paymentId ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Payment Status: </th>
                          <td><span className="text-uppercase">{paymentInfo?.status}</span></td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Club Revenue: </th>
                          <td>{paymentInfo?.clubRevenue ?? 0} {bookingDetails?.currency}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Application Fee: </th>
                          <td>{paymentInfo?.sportyaFee ?? 0} {bookingDetails?.currency}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Club Info</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Club Name: </th>
                          <td>
                            <Link to={`/accounts/clubs/${clubInfo.id}`}>{clubInfo?.clubName}</Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Location: </th>
                          <td>{clubInfo?.location?.name}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Court Info</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Court ID: </th>
                          <td>#{courtInfo?.courtId}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Court Name: </th>
                          <td>{courtInfo?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Surface: </th>
                          <td>{courtInfo?.surface}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Type: </th>
                          <td>{courtInfo?.type}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Sport Type: </th>
                          <td>{courtInfo?.sportType}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Size: </th>
                          <td>{courtInfo?.size}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{width: '480px'}}>Has Lighting: </th>
                          <td>{courtInfo?.hasLighting ? 'yes' : 'no'}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {editData && (
              <Row className="text-right mb-3">
                <Col>
                  <Button
                    className="btn btn-warning waves-effect waves-light btn-md ml-3"
                    onClick={() => setEditData(false)}
                  >
                    Cancel Edit
                  </Button>

                  <Button
                    className="btn btn-success waves-effect waves-light btn-md ml-3"
                    onClick={handleEdit}
                  >
                    Save Data
                  </Button>
                </Col>
              </Row>
            )}
          </>)}
        </Container>

        {successAlert ? (
          <SweetAlert
            success
            title={alertTitle || "Success"}
            onConfirm={() => {
              setSuccessAlert(false)
            }}
          >
            {alertDescription}
          </SweetAlert>
        ) : null}

        {cancelAlert ? (
          <SweetAlert
            title="Are you sure you want to cancel this booking?"
            warning
            showCancel
            cancelBtnText="No"
            confirmBtnText="Cancel it!"
            confirmButtonText="Yes, cancel it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleCancel}
            onCancel={() => setCancelAlert(false)}
          >
            <p>According to the Cancellation Policy of the Club, the Booking will be canceled <span className={`font-weight-bold text-${cancellationType === 'late' ? 'danger' : 'success'}`}>{cancellationType === 'late' ? 'WITHOUT' : 'WITH'} REFUND</span>.</p>
            {cancellationType === 'late' && !bookingDetails.internalBooking?.type && (
              <>
                <p>If you still want to issue the Full Refund to the customer, check the following box:</p>
                <FormGroup className="d-flex gap-10 flex-row-reverse justify-content-center">
                  <Label htmlFor="issueRefund" className="ml2">Issue Refund</Label>
                  <Input
                    type="checkbox"
                    name="issueRefund"
                    id="issueRefund"
                    checked={issueRefund}
                    onChange={() => setIssueRefund(!issueRefund)}
                  />
                </FormGroup>
              </>
            )}
          </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}

BookingDetails.propTypes = {
  bookingDetails: PropTypes.object,
  getBookingDetails: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ bookings = {} }) => ({
  loading: bookings.loading,
  bookingDetails: bookings.bookingDetails,
})

const mapDispatchToProps = dispatch => ({
  getBookingDetails: id => dispatch(getBookingDetails(id)),
  updateBookingDetails: data => dispatch(updateBookingDetails(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BookingDetails))
