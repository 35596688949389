import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap"
import { useToasts } from "react-toast-notifications"
import { Link } from "react-router-dom"
import moment from "moment"
import { isNumber } from "lodash"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { store as socketStore } from "components/Common/Socket/store"
import * as actions from "./actions"
import { deleteFriendlyMatch } from "./api"

const Details = ({
  getFriendlyMatch,
  friendlyMatchesState,
  match: { params },
}) => {

  useEffect(() => {
    getFriendlyMatch(params.id)
  }, [getFriendlyMatch, params.id])

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }
    getFriendlyMatch(params.id)
    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const getLocalDate = (date = new Date(), timeZone = 'Europe/Bucharest') => {
    return new Date((typeof date === 'string' ? new Date(date) : date)
      .toLocaleString('en-US', { timeZone }))
  }
  const matchFormats = {
    singleSet: 'Single Set',
    '2setsWithSuperTieBreak': '2 Sets + Super Tiebreak',
    '2setsOutOf3': '2 Sets out of 3',
    later: 'Not selected',
  }

  const match = friendlyMatchesState?.friendlyMatch;
  const localDate = getLocalDate(match.startDate, match.timezone);
  const invitationStatuses = {
    pending: { text: 'Pending', className: 'text-secondary' },
    accepted: { text: 'Accepted', className: 'text-success' },
    declined: { text: 'Declined', className: 'text-danger' },
    withdrawn: { text: 'Withdrawn', className: 'text-danger' },
    noMatch: { text: 'No-Match', className: 'text-danger' },
  }
  const statuses = {
    active: 'Open',
    canceled: 'Canceled',
    noMatch: 'No-Match',
    completed: 'Completed',
    deleted: 'Deleted',
  };
  let status = statuses[match?.status];
  if ([
      match.player?.invitation,
      match.playerPartner?.invitation,
      match.opponent?.invitation,
      match.opponentPartner?.invitation
  ].filter(status => ['declined', 'withdrawn', 'noMatch'].includes(status)).length && match?.status === 'active') {
    status = 'On-Hold';
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ListenerComponent
            listenFunction={listenForResponse}
            topic="friendly-matches-response"
          />
          <Card>
            <CardBody className="d-flex justify-content-between align-items-center">
              <h4>
                Friendly Match{" "}
                {`FM${match.matchId?.toString().padStart(5, "0")}`}
              </h4>
              {match.status !== "deleted" && (
                <span
                  className="btn btn-danger waves-effect waves-light btn-md ml-3"
                  target="_blank"
                  onClick={() => setModal(true)}
                >
                  Delete
                </span>
              )}
            </CardBody>
          </Card>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{"Match ID: "}</th>
                          <td>{`FM${match.matchId
                            ?.toString()
                            .padStart(5, "0")}`}</td>
                        </tr>
                        <tr>
                          <th scope="row">{`Start Date${
                            !match.pastMatch && " and Time"
                          }: `}</th>
                          <td>
                            {moment(localDate).format("YYYY-MM-DD")}{" "}
                            {!match.pastMatch &&
                              moment(localDate).format("HH:mm")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Sport Type: "}</th>
                          <td className="text-capitalize">{match.sportType}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Game Type: "}</th>
                          <td className="text-capitalize">{match.gameType}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Location: "}</th>
                          <td>{match.location?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Match Type: "}</th>
                          <td>{match.ranked ? "Ranked" : "Unranked"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">{"Match Format: "}</th>
                          <td>{matchFormats[match.format]}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Created by: "}</th>
                          <td>
                            <Link
                              to={`/accounts/players/${match?.player?.id}`}
                              className="text-dark"
                              target="_blank"
                            >
                              {match?.player?.firstName}{" "}
                              {match?.player?.lastName} #{match?.player?.userId}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Date Created: "}</th>
                          <td>
                            {moment(match.createdAt).format("YYYY-MM-DD HH:mm")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{"Status: "}</th>
                          <td>{status}</td>
                        </tr>
                        <tr>
                          <th scope="row">{"Score: "}</th>
                          <td>
                            {(match?.player?.score || []).length ||
                            match?.player?.type ||
                            match?.opponent?.type
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Table>
                    <tr>
                      <td>
                        {match.gameType === "doubles" && (
                          <div className="mb-1">
                            Average Team Level:{" "}
                            {((match.player?.gameLevel?.doubles ?? match.player?.gameLevelDouble ?? 0) +
                              (match.playerPartner?.gameLevel?.doubles ?? match.playerPartner?.gameLevelDouble ?? 0)) /
                              2}
                          </div>
                        )}
                        <Link
                          to={`/accounts/players/${match?.player?.id}`}
                          className="text-dark"
                          target="_blank"
                        >
                          {match?.player?.firstName} {match?.player?.lastName} #
                          {match?.player?.userId}
                        </Link>{" "}
                        {`Level-${
                          match.gameType === "doubles"
                            ? (match.player?.gameLevel?.doubles ?? match.player?.gameLevelDouble)
                            : (match.player?.gameLevel?.singles ?? match.player?.gameLevelSingle)
                        }`}
                        {match.gameType === "doubles" && (
                          <>
                            <br />
                            <Link
                              to={`/accounts/players/${match?.playerPartner?.id}`}
                              className="text-dark"
                              target="_blank"
                            >
                              {match?.playerPartner?.firstName}{" "}
                              {match?.playerPartner?.lastName} #
                              {match?.playerPartner?.userId}
                            </Link>{" "}
                            {`Level-${match.playerPartner?.gameLevel?.doubles ?? match.playerPartner?.gameLevelDouble}`}{" "}
                            <span
                              className={
                                invitationStatuses[
                                  match.playerPartner?.invitation
                                ]?.className
                              }
                            >
                              {
                                invitationStatuses[
                                  match.playerPartner?.invitation
                                ]?.text
                              }
                            </span>
                          </>
                        )}
                        {isNumber(match.matches?.[0]?.player?.points) && (
                          <div className="mt-1">{`+${match.matches?.[0]?.player?.points} Points`}</div>
                        )}
                      </td>
                      <td>
                        {match.matches?.[0]?.winner === "player" && (
                          <span class="badge badge-success">W</span>
                        )}
                        {match.matches?.[0]?.winner === "opponent" && (
                          <span class="badge badge-danger">L</span>
                        )}
                      </td>
                      <td>{match.player?.type === "BYE" ? "Abandoned" : ""}</td>
                      {(match.player?.score || []).map((set, index) => (
                        <td
                          className={
                            set.points > match?.opponent?.score?.[index]?.points
                              ? "font-weight-bold"
                              : ""
                          }
                        >
                          {set.points}
                          <sup>{set.tie}</sup>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td>
                        {match.gameType === "doubles" && (
                          <div className="mb-1">
                            Average Team Level:{" "}
                            {((match.opponent?.gameLevel?.doubles ?? match.opponent?.gameLevelDouble ?? 0) +
                              (match.opponentPartner?.gameLevel?.doubles ?? match.opponentPartner?.gameLevelDouble ?? 0)) /
                              2}
                          </div>
                        )}
                        <Link
                          to={`/accounts/players/${match?.opponent?.id}`}
                          className="text-dark"
                          target="_blank"
                        >
                          {match?.opponent?.firstName}{" "}
                          {match?.opponent?.lastName} #{match?.opponent?.userId}
                        </Link>{" "}
                        {`Level-${
                          match.gameType === "doubles"
                            ? (match.opponent?.gameLevel?.doubles ?? match.opponent?.gameLevelDouble)
                            : (match.opponent?.gameLevel?.singles ?? match.opponent?.gameLevelSingle)
                        }`}{" "}
                        <span
                          className={
                            invitationStatuses[match.opponent?.invitation]
                              ?.className
                          }
                        >
                          {invitationStatuses[match.opponent?.invitation]?.text}
                        </span>
                        {match.gameType === "doubles" && (
                          <>
                            <br />
                            <Link
                              to={`/accounts/players/${match?.opponentPartner?.id}`}
                              className="text-dark"
                              target="_blank"
                            >
                              {match?.opponentPartner?.firstName}{" "}
                              {match?.opponentPartner?.lastName} #
                              {match?.opponentPartner?.userId}
                            </Link>{" "}
                            {`Level-${match.opponentPartner?.gameLevel?.doubles ?? match.opponentPartner?.gameLevelDouble}`}{" "}
                            <span
                              className={
                                invitationStatuses[
                                  match.opponentPartner?.invitation
                                ]?.className
                              }
                            >
                              {
                                invitationStatuses[
                                  match.opponentPartner?.invitation
                                ]?.text
                              }
                            </span>
                          </>
                        )}
                        {isNumber(match.matches?.[0]?.opponent?.points) && (
                          <div className="mt-1">{`+${match.matches?.[0]?.opponent?.points} Points`}</div>
                        )}
                      </td>
                      <td>
                        {match.matches?.[0]?.winner === "opponent" && (
                          <span class="badge badge-success">W</span>
                        )}
                        {match.matches?.[0]?.winner === "player" && (
                          <span class="badge badge-danger">L</span>
                        )}
                      </td>
                      <td>
                        {match.opponent?.type === "BYE" ? "Abandoned" : ""}
                      </td>
                      {(match.opponent?.score || []).map((set, index) => (
                        <td
                          className={
                            set.points > match?.player?.score?.[index]?.points
                              ? "font-weight-bold"
                              : ""
                          }
                        >
                          {set.points}
                          <sup>{set.tie}</sup>
                        </td>
                      ))}
                    </tr>
                    {((match?.player?.score || []).length ||
                      match?.player?.type ||
                      match?.opponent?.type) && (
                      <tr>
                        <td
                          colSpan={match.opponent?.score?.length + 2}
                          className="text-right"
                        >
                          Score added/updated date{" "}
                          {moment(
                            match.scoreAddedDate
                              ? match.scoreAddedDate
                              : match.pastMatch
                              ? match.createdAt
                              : undefined
                          ).format("YYYY-MM-DD HH:mm")}
                        </td>
                      </tr>
                    )}
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{"Delete Friendly Match"}</ModalHeader>
          <ModalBody>
            {"Are you sure you want to delete this friendly match?"}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={async () => {
                await deleteFriendlyMatch({
                  socketClientId,
                  matchId: match._id,
                })
                toggle()
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button color="secondary" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = ({
  friendlyMatches: friendlyMatchesState = {},
}) => ({
  friendlyMatchesState,
})

export default connect(mapStatetoProps, {
  getFriendlyMatch: actions.getFriendlyMatch,
})(Details)
