import { get, patch, post } from "helpers/api_helper"

export const getLogs = async (compId, filters, activePage) => {
  let response
  const formattedFilters = Object.keys(filters).map(filterKey => {
    switch (filterKey) {
      case "subscribeSL":
        return filters[filterKey] ? "subscribeSl=true" : ""
      case "subscribeML":
        return filters[filterKey] ? "subscribeMl=true" : ""
      case "subscribeWL":
        return filters[filterKey] ? "subscribeWl=true" : ""
      case "unsubscribeML":
        return filters[filterKey] ? "unsubscribeMl=true" : ""
      case "unsubscribeWL":
        return filters[filterKey] ? "unsubscribeWl=true" : ""
      case "unsubscribeSL":
        return filters[filterKey] ? "unsubscribeSl=true" : ""
      case "promote":
        return filters[filterKey] ? "promote=true" : ""
      case "reserve":
        return filters[filterKey] ? "reserve=true" : ""
      default:
        break
    }
  })
  try {
    const url = `/logs/entries`
    const params = new URLSearchParams(
      `?limit=40&page=${
        activePage + 1
      }&competitionId=${compId}&${formattedFilters.join("&")}`
    )
    response = await get(url, { params })
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getMatches = async competitionId => {
  let response
  try {
    response = await get(`/matches/${competitionId}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getMainDraw = async (competitionId, mlNumber) => {
  let response
  try {
    response = await get(
      `/matches/${competitionId}/main-draw?mlNumber=${mlNumber}`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const updateMatches = async (
  matchId,
  compDetails,
  matchDetails,
  type,
  socketClientId
) => {
  let response

  const { phases, drawModel } = compDetails

  try {
    response = await patch(
      `/admin/matches/${matchId}${type ? `/${type}` : "/none"}${
        phases?.length === 0 && drawModel === "groups" ? "/true" : ""
      }`,
      { socketClientId, ...matchDetails, competitionId: compDetails._id }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const createMatch = async (
  player1,
  opponent1,
  event,
  competition,
  index,
  phaseLength,
  socketClientId
) => {
  const { gameType, drawModel, level, _id, phases } = competition

  const player = player1?.user
  const playerPartner = player1?.partner

  const opponent = opponent1?.user
  const opponentPartner = opponent1?.partner

  let response

  const phase = phases?.length > 0 ? phases[0] : null

  const payload = {
    matchType: "competition",
    // ended: false,
    competition: {
      gameType: phase?.gameType,
      position: index + 1, // ?
      drawType: phase?.drawModel,
      type: event?.category,
      stage: 1,
      level: level,
      phaseId: phase?._id,
      id: _id,
      eventId: event?._id,
      eventName: event?.name,
      finalStage: Math.log2(phase?.mlNumber), // mlSize ?
      winner: null,
    },

    player: {
      score: [],
      points: 0,
      userId: player?.userId,
      rank: player?.rank,
      firstName: player?.firstName,
      lastName: player?.lastName,
      ...(player?.type === "BYE" && { type: "BYE" }),
      location: {
        name: player?.location?.name,
        coords: {
          lat: player?.location?.coords?.lat,
          lng: player?.location?.coords?.lng,
          city: player?.location?.city,
          country: player?.location?.country,
        },
      },
      competitionRank: player?.competitionRank,
      seeded: false,
      level:
        phase?.gameType === "singles"
          ? player?.gameLevel?.singles
          : player?.gameLevel?.doubles,
      id: player?.id,
      ignorePoints: player?.ignorePoints,
      pointsAdjustment: player?.pointsAdjustment,
      profilePicture: player?.profilePicture,
      userPoints: player?.userPoints,
    },

    ...(playerPartner?.userId && {
      playerPartner: {
        score: [],
        points: 0,
        userId: playerPartner?.userId,
        rank: playerPartner?.rank,
        firstName: playerPartner?.firstName,
        lastName: playerPartner?.lastName,
        location: {},
        userPoints: 200,
        level: playerPartner?.gameLevel?.doubles,
        id: playerPartner?.id,
        winner: null,
      },
    }),

    opponent: {
      score: [],
      points: 0, // ?
      userId: opponent?.userId,
      rank: opponent?.rank,
      ...(opponent?.type === "BYE" && { type: "BYE" }),
      firstName: opponent?.firstName,
      lastName: opponent?.lastName,
      location: {
        name: opponent?.location?.name,
        coords: {
          lat: opponent?.location?.coords?.lat,
          lng: opponent?.location?.coords?.lng,
          city: opponent?.location?.city,
          country: opponent?.location?.country,
        },
      },
      competitionRank: opponent?.competitionRank,
      seeded: false,
      id: opponent?.id,
      level:
        phase?.gameType === "singles"
          ? opponent?.gameLevel?.singles
          : opponent?.gameLevel?.doubles,
      ignorePoints: opponent?.ignorePoints,
      pointsAdjustment: opponent?.pointsAdjustment,
      profilePicture: opponent?.profilePicture,
      userPoints: opponent?.userPoints,
    },

    ...(opponentPartner?.userId && {
      opponentPartner: {
        score: [],
        points: 0,
        userId: opponentPartner?.userId,
        rank: opponentPartner?.rank,
        firstName: opponentPartner?.firstName,
        lastName: opponentPartner?.lastName,
        location: {},
        userPoints: 200,
        level: opponentPartner?.gameLevel?.doubles,
        id: opponentPartner?.id,
        winner: null,
      },
    }),
  }

  try {
    response = await post(`/matches`, { ...payload, socketClientId })
  } catch (error) {
    console.log(error)
  }

  return response
}
